import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import MortgageResults from "../../components/MortgageResults/MortgageResults";
import MortgageSearch from "../../components/MortgageSearch/MortgageSearch";
import FormContext from "../../context/FormContext";
import MortgageResultsContainer from "../MortgageResultsContainer/MortgageResultsContainer";
import MortgageSearchContainer from "../MortgageSearchContainer/MortgageSearchContainer";
import StyledAlert from "../../components/shared/Alert/Alert";
import { AlertTitle, Typography } from "@mui/material";
import StyledSnackbar from "../../components/shared/Snackbar/Snackbar";
import Separator, {
  SeparatorDiv,
  SeparatorTitle,
} from "../../components/shared/Separator/Separator";
import FooterContainer from "../FooterContainer/FooterContainer";
import Footer from "../../components/Footer/Footer";
import MortgageSearchHeader from "../../components/MortgageSearchHeader/MortgageSearchHeader";
import { useTheme } from "styled-components";
import { Theme } from "src/interfaces";

type StyledProps = {
  className?: string;
};

const Calculator: React.FC<StyledProps> = ({ className }) => {
  const theme = useTheme() as Theme;

  const { form, isLoading, searched } = useContext(FormContext);
  const searchRef = useRef(null);
  const resultsRef = useRef(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const scrollToResults = () => {
    if (resultsRef.current) {
      const element = resultsRef.current as HTMLDivElement;
      element.scrollTop = element.scrollHeight;
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (form && resultsRef && searched) {
      setTimeout(scrollToResults, 200);
      setOpenAlert(true);
    }
  }, [form, searched]);

  return (
    <div
      id="calculator-plugin"
      className={className}
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {/* {isLoading ? <StyledLoading size={120} /> : null} */}
      {theme?.config?.showTopText ? (
        <>
          <Typography
            color="primary"
            style={{
              margin: "0px",
              fontSize: "36px",
              fontWeight: "600",
              lineHeight: "43.88px",
              letterSpacing: "-0.01em",
              color: theme?.customization?.primaryColor,
            }}
          >
            Our best rates
          </Typography>
          <p
            style={{
              margin: "33px auto 30px 0px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            Find out the best deals available to you now.{" "}
          </p>
        </>
      ) : null}
      {form && form.length === 0 && searched ? (
        <StyledSnackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={() => setOpenAlert(false)}
          vertical="top"
          horizontal="right"
        >
          <div>
            <StyledAlert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              Your search returned {form?.length} results.
            </StyledAlert>
          </div>
        </StyledSnackbar>
      ) : null}
      <MortgageSearchContainer ref={searchRef}>
        <MortgageSearchHeader />
        <MortgageSearch apiKey={theme.apiKey} />
      </MortgageSearchContainer>
      {/* <SeparatorDiv>
                <Typography variant="h5">
                    <SeparatorTitle>{isLoading ? "Generating Results" : "Results"}</SeparatorTitle>
                </Typography>
                <Separator width={isLoading ? "80%" : "90%"} margin="4px 20px" />
            </SeparatorDiv> */}
      <MortgageResultsContainer ref={resultsRef}>
        <MortgageResults form={form} />
      </MortgageResultsContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

const StyledCalculator = styled(Calculator)`
  display: flex;
  flex-direction: column;
  ${() => {
    const theme = useTheme() as Theme;

    return theme?.config?.notFullWidth
      ? `
      width: 100%;
      max-width: 1014px;
      margin: auto;
      padding: 20px;
    `
      : null;
  }}
`;

export default StyledCalculator;
