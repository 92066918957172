const { REACT_APP_COMMS_URL, REACT_APP_CHATAPP_URL, REACT_APP_MORTGAGE_URL } =
  process.env;

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  exMobileS: "319px",
  exMobileM: "374px",
  exMobileL: "424px",
  exTablet: "767px",
  exLaptop: "1023px",
  upperMobileS: "321px",
  upperMobileM: "376px",
  upperMobileL: "426px",
  upperTablet: "769px",
  upperLaptop: "1025px",
};

export const config = {
  commsUrl: REACT_APP_COMMS_URL,
  chatAppUrl: REACT_APP_CHATAPP_URL,
  mortgageApi: REACT_APP_MORTGAGE_URL,
  themes: {
    ruby: {
      primary: {
        main: "#ff385c",
        dark: "#e63253",
        light: "#fff2f5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#595959",
        contrastText: "#fff",
      },
      common: {
        white: "#F3F3F3",
        black: "#4F4F4F",
      },
      action: {
        hover: "#ffcfd9",
      },
    },
    royal: {
      primary: {
        main: "#002554",
        dark: "#335076",
        light: "#b3bdcc",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#fff",
      },
      common: {
        white: "#F3F3F3",
        black: "#4F4F4F",
      },
      action: {
        hover: "#b3bdcc",
      },
    },
    hazelnut: {
      primary: {
        main: "#b1a296",
        dark: "#8e8278",
        light: "#d0c7c0",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#fff",
      },
      common: {
        white: "#F3F3F3",
        black: "#4F4F4F",
      },
      action: {
        hover: "#e0dad5",
      },
    },
    jade: {
      primary: {
        main: "#41b3a3",
        dark: "#348f83",
        light: "#a0d9d2",
        contrastText: "#fff",
      },
      secondary: {
        main: "#fff",
        contrastText: "#fff",
      },
      common: {
        white: "#F3F3F3",
        black: "#4F4F4F",
      },
      action: {
        hover: "#c6e8e4",
      },
    },
    basic: {
      primary: {
        main: "#EFEFEF",
        dark: "#e63253",
        light: "#fff2f5",
        contrastText: "#000",
      },
      secondary: {
        main: "#595959",
        contrastText: "#fff",
      },
      common: {
        white: "#F3F3F3",
        black: "#4F4F4F",
      },
      action: {
        hover: "#ffcfd9",
      },
    },
    custom: {
      primary: {
        main: "#ff385c",
        // dark: "#e63253",
        // light: "#fff2f5",
        // contrastText: "#fff",
      },
      secondary: {
        main: "#595959",
        // contrastText: "#fff"
      },
      // common: {
      //   white: "#F3F3F3",
      //   black: "#4F4F4F",
      // },
      // action: {
      //   hover: "#ffcfd9"
      // }
    },
  },
  device: {
    mobileS: `${size.mobileS}`,
    mobileM: `${size.mobileM}`,
    mobileL: `${size.mobileL}`,
    tablet: `${size.tablet}`,
    laptop: `${size.laptop}`,
    exMobileS: `${size.exMobileS}`,
    exMobileM: `${size.exMobileM}`,
    exMobileL: `${size.exMobileL}`,
    exTablet: `${size.exTablet}`,
    exLaptop: `${size.exLaptop}`,
    upperMobileS: `${size.upperMobileS}`,
    upperMobileM: `${size.upperMobileM}`,
    upperMobileL: `${size.upperMobileL}`,
    upperTablet: `${size.upperTablet}`,
    upperLaptop: `${size.upperLaptop}`,
  },
  fontTypes: [".otf", ".ttf"],
  fontStyles: [
    { name: "ExtraLight", weight: 200 },
    { name: "Light", weight: 300 },
    { name: "Regular", weight: 400 },
    { name: "Bold", weight: 700 },
  ],
};

export default config;
