import { Theme } from "../../interfaces";

export const getDefaultMortgagePurpose = (theme: Theme) => {
    return [
        {
            label: "Purchase",
            disabled: theme?.config?.hidePurchase,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
            }),
        },
        {
            label: "Remortgage",
            disabled: theme?.config?.hideRemortgage,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Standard",
                TermUnit: "Years",
            }),
        },
        {
            label: "First Time Buyer",
            disabled: theme?.config?.hideFTB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
                Applicant1: {
                    ApplicantType: "First_Time_Buyer",
                },
            }),
        },
        {
            label: "Home Mover",
            disabled: theme?.config?.hideHomeMover,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
                Applicant1: {
                    ApplicantType: "Existing_Mortgage_Single",
                },
            }),
        },
        {
            label: "Buy to Let Purchase",
            disabled: theme?.config?.hideBTLPurchase,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
            }),
        },
        {
            label: "Buy to Let Remortgage",
            disabled: theme?.config?.hideBTLRemortgage,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
            }),
        },
        ...(theme.apiKey === "cbdefc9b-1259-44c1-98cb-8abf018cdd3b"
            ? [
                  {
                      label: "Buy to Let",
                      value: JSON.stringify({
                          MortgageType: "Buy_To_Let",
                          TermUnit: "Years",
                      }),
                  },
              ]
            : []),
        {
            label: "Let to Buy",
            disabled: theme?.config?.hideLTB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Let_To_Buy",
                TermUnit: "Years",
            }),
        },
        {
            label: "Holiday Let",
            disabled: theme?.config?.hideHolidayLet,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                PropertyDetails: { PropertyUse: "Holiday_Let" },
                TermUnit: "Years",
            }),
        },
        {
            label: "Bridging Loan",
            disabled: theme?.config?.hideBridgingLoan,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Bridging_Loan",
                TermUnit: "Months",
            }),
        },
        {
            label: "Second Charge",
            disabled: theme?.config?.hideSecondCharge,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Bridging_Loan",
                BridgingDetails: {
                    BridgingLoanPurpose: "Second_Charge",
                },
                TermUnit: "Months",
            }),
        },
        {
            label: "Ltd Company Purchase",
            disabled: theme?.config?.hideLtdCompany,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
                BuyToLetDetails: { LimitedCompanySPV: "Yes" },
                Filters: {
                    LimitedCompanySPV: "Yes",
                },
            }),
        },
        {
            label: "House Multiple Occupancy",
            disabled: theme?.config?.hideHMO,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                BuyToLetDetails: { BuildingUse: "HMO" },
                TermUnit: "Years",
            }),
        },
        {
            label: "Multi Unit Building",
            disabled: theme?.config?.hideMUB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                BuyToLetDetails: { BuildingUse: "MUB" },
                TermUnit: "Years",
            }),
        },
    ];
};

export const getBTLBrokerMortgagePurpose = (theme: Theme) => {
    return [
        {
            label: "Buy to Let Purchase",
            disabled: theme?.config?.hideBTLPurchase,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
            }),
        },
        {
            label: "Buy to Let Remortgage",
            disabled: theme?.config?.hideBTLRemortgage,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
            }),
        },
        {
            label: "Purchase",
            disabled: theme?.config?.hidePurchase,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
            }),
        },
        {
            label: "Remortgage",
            disabled: theme?.config?.hideRemortgage,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Standard",
                TermUnit: "Years",
            }),
        },
        {
            label: "First Time Buyer",
            disabled: theme?.config?.hideFTB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
                Applicant1: {
                    ApplicantType: "First_Time_Buyer",
                },
            }),
        },
        {
            label: "Home Mover",
            disabled: theme?.config?.hideHomeMover,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Standard",
                TermUnit: "Years",
                Applicant1: {
                    ApplicantType: "Existing_Mortgage_Single",
                },
            }),
        },
        {
            label: "Let to Buy",
            disabled: theme?.config?.hideLTB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Let_To_Buy",
                TermUnit: "Years",
            }),
        },
        {
            label: "Holiday Let",
            disabled: theme?.config?.hideHolidayLet,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                PropertyDetails: { PropertyUse: "Holiday_Let" },
                TermUnit: "Years",
            }),
        },
        {
            label: "Bridging Loan",
            disabled: theme?.config?.hideBridgingLoan,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Bridging_Loan",
                TermUnit: "Months",
            }),
        },
        {
            label: "Second Charge",
            disabled: theme?.config?.hideSecondCharge,
            value: JSON.stringify({
                ReasonForMortgage: "Remortgage",
                MortgageType: "Bridging_Loan",
                BridgingDetails: {
                    BridgingLoanPurpose: "Second_Charge",
                },
                TermUnit: "Months",
            }),
        },
        {
            label: "Ltd Company Purchase",
            disabled: theme?.config?.hideLtdCompany,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                TermUnit: "Years",
                BuyToLetDetails: { LimitedCompanySPV: "Yes" },
                Filters: {
                    LimitedCompanySPV: "Yes",
                },
            }),
        },
        {
            label: "House Multiple Occupancy",
            disabled: theme?.config?.hideHMO,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                BuyToLetDetails: { BuildingUse: "HMO" },
                TermUnit: "Years",
            }),
        },
        {
            label: "Multi Unit Building",
            disabled: theme?.config?.hideMUB,
            value: JSON.stringify({
                ReasonForMortgage: "Purchase",
                MortgageType: "Buy_To_Let",
                BuyToLetDetails: { BuildingUse: "MUB" },
                TermUnit: "Years",
            }),
        },
    ];
};
