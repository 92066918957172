import React from "react";

import { Typography, Box, Grid, alpha, Button } from "@mui/material";
import styled, { useTheme } from "styled-components";

import ChatNowFooter from "../../assets/images/ChatNow.svg";
import Message from "../../assets/images/Message.svg";
import { Theme } from "src/interfaces";

type StyledProps = {
  className?: string;
};

const FooterTextDiv = styled.div`
  background: #fff;
  text-align: center;
  padding: 20px 12px;
  border-top: 1px solid #d7d7d7;
`;

const FooterText = styled.span`
  font-weight: 500;
  display: inline-block;
  width: 50%;
`;

const {
  REACT_APP_CALENDAR_URL,
  REACT_APP_CHATAPP_URL,
  REACT_APP_PARKERSLIDE_URL,
  REACT_APP_BEST_BUY_URL,
} = process.env;

const Footer: React.FC<StyledProps> = ({ className }) => {
  const theme = useTheme() as Theme;

  return (
    <div className={className}>
      {!theme?.config?.hideComplianceText && (
        <FooterTextDiv>
          <Typography
            maxWidth="1183px"
            margin="auto"
            variant="h6"
            fontSize={{
              md: 16,
              xs: 12,
            }}
            fontWeight={600}
            lineHeight="24px"
            color="#000"
          >
            {theme?.config?.complianceText ||
              "The rates quoted above were correct at the time of writing and are subject to change at any time at the lender’s discretion. Speaking to a mortgage broker is the best way to keep track of the rates available at any given time."}
          </Typography>
        </FooterTextDiv>
      )}
      {!theme?.config?.hideBanner && (
        <Box
          minHeight="161px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ background: alpha(theme.customization?.primary.main!, 0.8) }}
          position="relative"
        >
          <Grid
            maxWidth="lg"
            container
            spacing={2}
            margin="auto"
            sx={{
              zIndex: 1,
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                fontWeight={700}
                fontSize={36}
                lineHeight="43px"
                color="#FFF"
              >
                {theme?.config?.getStartedOnlineLabel || "Don’t want to wait?"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              justifyContent="flex-end"
              display="flex"
              sx={{
                "@media (max-width: 895px)": {
                  justifyContent: "unset",
                },
              }}
            >
              {theme?.apps?.chatapp ? (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    border: "2px solid #FFF",
                    borderRadius: "50px",
                    color: "#FFF",
                    textTransform: "capitalize",
                    marginRight: "21px",
                    "&:hover": {
                      border: "2px solid #FFF",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_CHATAPP_URL}/client?apiKey=${theme?.apps?.chatapp}`,
                      "_blank",
                    )
                  }
                >
                  {theme?.config?.getStartedOnlineText || "Get Started Online"}
                </Button>
              ) : null}
              {theme?.apps?.parkerslide ? (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    border: "2px solid #FFF",
                    borderRadius: "50px",
                    color: "#FFF",
                    textTransform: "capitalize",
                    marginRight: "21px",
                    "&:hover": {
                      border: "2px solid #FFF",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_PARKERSLIDE_URL}/client?apiKey=${theme?.apps?.parkerslide}`,
                      "_blank",
                    )
                  }
                >
                  {theme?.config?.getStartedOnlineText || "Get Started Online"}
                </Button>
              ) : null}
              {theme?.apps?.calendar ? (
                <Button
                  variant="outlined"
                  sx={{
                    border: "2px solid #FFF",
                    borderRadius: "50px",
                    backgroundColor: "#FFF",
                    color: `${theme.customization?.primary.main!} !important`,
                    textTransform: "capitalize",
                    "&:hover": {
                      border: "2px solid #FFF",
                      color: theme.customization?.primary.main,
                      backgroundColor: "#FFF",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_CALENDAR_URL}/client?apiKey=${theme?.apps?.calendar}`,
                      "_blank",
                    )
                  }
                >
                  <div
                    style={{
                      backgroundColor: theme.customization?.primary.main!,
                      maskImage: `url(${Message})`,
                      WebkitMaskImage: `url(${Message})`,
                      width: "27.5px",
                      height: "24.75px",
                    }}
                  />
                  {/* <Message /> */}
                  {theme?.config?.bookAppointmentText || "Book an appointment"}
                </Button>
              ) : null}
              {theme?.apps?.bestbuy ? (
                <Button
                  variant="outlined"
                  sx={{
                    border: "2px solid #FFF",
                    borderRadius: "50px",
                    backgroundColor: "#FFF",
                    color: `${theme.customization?.primary.main!} !important`,
                    textTransform: "capitalize",
                    "&:hover": {
                      border: "2px solid #FFF",
                      color: theme.customization?.primary.main,
                      backgroundColor: "#FFF",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${REACT_APP_BEST_BUY_URL}/client?apiKey=${theme?.apps?.bestbuy}`,
                      "_blank",
                    )
                  }
                >
                  <div
                    style={{
                      backgroundColor: theme.customization?.primary.main!,
                      maskImage: `url(${Message})`,
                      WebkitMaskImage: `url(${Message})`,
                      width: "27.5px",
                      height: "24.75px",
                    }}
                  />
                  {theme?.config?.compareDealsText || "Compare Deals"}
                </Button>
              ) : null}
              {theme?.config?.getStartedOnlineLink ? (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    border: "2px solid #FFF",
                    borderRadius: "50px",
                    color: "#FFF",
                    textTransform: "capitalize",
                    marginRight: "21px",
                    "&:hover": {
                      border: "2px solid #FFF",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      `${theme?.config?.getStartedOnlineLink}`,
                      "_blank",
                    )
                  }
                >
                  {theme?.config?.getStartedOnlineText || "Get Started Online"}
                </Button>
              ) : null}
            </Grid>
          </Grid>
          {/* <ChatNowFooter 
            style={{
              color: alpha(theme.customization?.primary.main!, 0.8),
              width: '100%',
              position: "absolute",
              top: 0,
              left: 0,
            }}
          /> */}
          {/* <div
            style={{
              backgroundColor: alpha(theme.customization?.primary.main!, 0.8),
              maskImage: `url(${ChatNowFooter})`, 
              WebkitMaskImage: `url(${ChatNowFooter})`,
              width: '100%',
              height: '100%',
              position: "absolute",
              top: 0,
              left: 0,
            }}
          /> */}
        </Box>
      )}
    </div>
  );
};

const StyledFooter = styled(Footer)`
  display: block;
  width: 100%;
`;

export default StyledFooter;
