import config from "../../assets/data/config";
import styled from "styled-components";

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;

  @media (max-width: ${config.device.mobileL}) {
    width: 100%;
    margin: 10px 0;
  }

  @media (min-width: ${config.device.upperMobileL}) and (max-width: ${config
      .device.tablet}) {
  }
`;

export default InputDiv;
