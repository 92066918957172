import ReactGA4 from "react-ga4";
import ReactGA, { EventArgs } from "react-ga";
import { v4 } from "uuid";

const sessionId = v4();

export const isUniversalAnalytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("UA-");
};

export const isGA4Analytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("G-");
};

export const initializeUniversalAnalytics = (
  googleAnalyticsTrackingId: string,
) => {
  ReactGA.initialize(googleAnalyticsTrackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const initializeGA4Analytics = (googleAnalyticsTrackingId: string) => {
  let gaOptions = {};

  if (sessionId) {
    gaOptions = {
      cookieDomain: "auto",
      clientId: sessionId,
    };
  }
  ReactGA4.initialize(googleAnalyticsTrackingId, {
    gaOptions,
  });
  ReactGA4.send("pageview");
};

export const initializeGoogleAnalytics = (
  googleAnalyticsTrackingId: string[],
) => {
  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id),
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id),
    );

    if (uaIndex !== -1) {
      initializeUniversalAnalytics(googleAnalyticsTrackingId[uaIndex]);
    }

    if (ga4Index !== -1) {
      initializeGA4Analytics(googleAnalyticsTrackingId[ga4Index]);
    }
  }
};

export const trackGAEvent = (
  googleAnalyticsTrackingId: string[],
  event: EventArgs,
) => {
  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id),
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id),
    );
    if (uaIndex !== -1) {
      ReactGA.event({
        ...event,
        nonInteraction: true,
      });
    }

    if (ga4Index !== -1) {
      ReactGA4.event({
        ...event,
        nonInteraction: true,
      });
    }
  }
};
