import styled from "styled-components";

type StyledProps = {
  className?: string;
  children?: React.ReactNode;
};

const FormContainer: React.FC<StyledProps> = ({ className, children }) => (
  <div className={`form-container ${className}`}>{children}</div>
);

const StyledFormContainer = styled(FormContainer)`
  display: flex;
  justify-content: center;
`;

export default StyledFormContainer;
