import { renderApp } from ".";

const widgetDivs = document.querySelectorAll(".mp-best-buy-rate-calculator");

widgetDivs.forEach((div: any) => {
    const apiKey = div.dataset.apikey;
    const config = {
        defaultmortgagetype: div.dataset.defaultmortgagetype,
        defaultpaymentmethod: div.dataset.defaultpaymentmethod,
        defaultterm: parseInt(div.dataset.defaultterm),
        defaultpropertyvalue: div.dataset.defaultpropertyvalue,
        defaultloan: div.dataset.defaultloan,
        defaultrent: parseFloat(div.dataset.defaultrent),
        defaultownership: div.dataset.defaultownership,
    };
    renderApp(apiKey, config as any, div);
});

const createBestBuyRateCalculatorDiv = (htmlElement: HTMLElement): HTMLElement => {
    if (htmlElement) {
        return htmlElement;
    }

    const element = document.createElement("div");
    element.id = "mp-best-buy-rate-calculator";

    document.body.appendChild(element);

    return element;
};

const isChatAppExisting = () => {
    return document.querySelector("#mp-best-buy-rate-calculator") !== null;
};

const MeetParkerBestBuyRateCalculator = {
    init: (apiKey: string, config: any = {}, element: HTMLElement) => {
        console.log("here");
        if (isChatAppExisting()) {
            document.body.removeChild(document.querySelector("#mp-best-buy-rate-calculator")!);
        }
        renderApp(apiKey, config as any, createBestBuyRateCalculatorDiv(element));
    },
};

export default MeetParkerBestBuyRateCalculator;
