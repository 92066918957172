import config from "../data/config";
import { createTheme } from "@mui/material/styles";

export const ruby = createTheme({
  palette: {
    primary: {
      main: config.themes.ruby.primary.main,
      // dark: config.themes.ruby.primary.dark,
      // light: config.themes.ruby.primary.light,
      // contrastText: config.themes.ruby.primary.contrastText,
    },
    secondary: {
      main: config.themes.ruby.secondary.main,
      // contrastText: config.themes.ruby.secondary.contrastText,
    },
    // common: {
    //     white: config.themes.ruby.common.white,
    //     black: config.themes.ruby.common.black,
    // },
    // action: {
    //     hover: config.themes.ruby.action.hover,
    // },
  },
});

export const royal = createTheme({
  palette: {
    primary: {
      main: config.themes.royal.primary.main,
      dark: config.themes.royal.primary.dark,
      light: config.themes.royal.primary.light,
    },
    secondary: {
      main: config.themes.royal.secondary.main,
    },
    common: {
      white: config.themes.royal.common.white,
      black: config.themes.royal.common.black,
    },
    action: {
      hover: config.themes.royal.action.hover,
    },
  },
});

export const hazelnut = createTheme({
  palette: {
    primary: {
      main: config.themes.hazelnut.primary.main,
      dark: config.themes.hazelnut.primary.dark,
      light: config.themes.hazelnut.primary.light,
    },
    secondary: {
      main: config.themes.hazelnut.secondary.main,
    },
    common: {
      white: config.themes.hazelnut.common.white,
      black: config.themes.hazelnut.common.black,
    },
    action: {
      hover: config.themes.hazelnut.action.hover,
    },
  },
});

export const jade = createTheme({
  palette: {
    primary: {
      main: config.themes.jade.primary.main,
      dark: config.themes.jade.primary.dark,
      light: config.themes.jade.primary.light,
    },
    secondary: {
      main: config.themes.jade.secondary.main,
    },
    common: {
      white: config.themes.jade.common.white,
      black: config.themes.jade.common.black,
    },
    action: {
      hover: config.themes.jade.action.hover,
    },
  },
});

export const basic = createTheme({
  palette: {
    primary: {
      main: config.themes.basic.primary.main,
      dark: config.themes.basic.primary.dark,
      light: config.themes.basic.primary.light,
    },
    secondary: {
      main: config.themes.basic.secondary.main,
    },
    common: {
      white: config.themes.basic.common.white,
      black: config.themes.basic.common.black,
    },
    action: {
      hover: config.themes.basic.action.hover,
    },
  },
});

export const custom = createTheme({
  palette: {
    primary: {
      main: config.themes.basic.primary.main,
      // dark: config.themes.basic.primary.dark,
      // light: config.themes.basic.primary.light,
    },
    secondary: {
      main: config.themes.basic.secondary.main,
    },
    // common: {
    //     white: config.themes.basic.common.white,
    //     black: config.themes.basic.common.black,
    // },
    // action: {
    //     hover: config.themes.basic.action.hover,
    // },
  },
});
