import { zodResolver } from "@hookform/resolvers/zod";
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputBase,
    Modal,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled as muiStyled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Theme } from "src/interfaces";
import styled, { useTheme } from "styled-components";
import { validate as isValidUUID } from "uuid";
import * as z from "zod";
import Chat from "../../assets/images/Chat.png";
import ClientContext from "../../context/ClientContext";
import FormContext from "../../context/FormContext";
import { Lender, LenderProfile } from "../../interfaces/lenders";
import { isEmail } from "../../utils/config";
import { numberWithComma } from "../../utils/number";
import Image from "../shared/Image/Image";
import StyledText from "../shared/Text/Text";

type MortgageResultsProps = {
    form: any;
};

interface Data {
    LenderCode: string;
    MortgageClass: string;
    InitialPayRate: number;
    InitialMonthlyPayment: number;
    FeesTotal: number;
    StandardVariableRate: number;
    MaxLTVAvailable: number;
    InitialRatePeriodMonths: number;
    Enquire: string;
    MoreInfo: string;
    //   EnquireWithLender: string;
    //   LenderCriteriaUrl: string;
}

const contactFormSchema = z.object({
    firstName: z.string().min(1, "First name is required."),
    lastName: z.string().min(1, "Last name is required."),
    phone: z.string().min(1, "Phone number is required"),
    email: z.string().email(),
    town: z.string().min(1, "Town is required"),
    enquiry: z.string().max(255).min(1, "Enquiry is required."),
});

type FormData = z.infer<typeof contactFormSchema>;

const CustomTextField = muiStyled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 20,
        position: "relative",
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        fontSize: 16,
        width: "100%",
        padding: "10px 12px",
    },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    isEmail?: boolean;
    disablePadding?: boolean;
    id: keyof Data;
    label: string;
    numeric?: boolean;
    email?: boolean;
    tooltip?: React.ReactNode;
}

const StyledTableSortLabel = styled(TableSortLabel)`
    .MuiTableSortLabel-icon {
        color: #ededed !important;
    }
`;

const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.head} .Mui-active`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.head}:hover`]: {
        color: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.head} .Mui-active:hover`]: {
        color: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        textAlign: "center",
        fontSize: "18px",
        "@media screen and (max-width: 767px)": {
            fontSize: "14px",
        },
    },
}));

const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#FFF",
    },

    "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.action.hover,
    },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const theme = useTheme() as Theme;

    let headCells: HeadCell[] = [
        {
            id: "InitialPayRate",
            label: "Interest Rate",
            tooltip:
                "The mortgage interest rate is the percentage that you'll pay on top of the repayment amount each year.",
        },
        {
            id: "MortgageClass",
            label: "Mortgage Type",
            tooltip:
                "Mortgage interest rates can be fixed, meaning they remain constant for the duration of the loan term, or they can be variable or tracker, meaning they fluctuate based on market conditions.",
        },
        {
            id: "InitialMonthlyPayment",
            label: "Monthly Repayment Amount",
            tooltip: "The amount you repay each month.",
        },
        {
            id: "FeesTotal",
            label: "Total Fees",
            tooltip:
                "Total amount of all applicable fees payable. This value can be added to the mortgage amount.",
        },
        {
            id: "StandardVariableRate",
            label: "APRC",
            tooltip:
                "Annual Percentage Rate of Charge. Combines both the introductory rate, the standard variable rate, and applicable fees to show the lifetime cost of the loan if you kept the mortgage until it was paid off.",
        },
        {
            id: "MaxLTVAvailable",
            label: "Max LTV",
            tooltip: "This shows the maximum you can borrow and at the given interest rate.",
        },
        //   {
        //     id: "EnquireWithLender",
        //     label: "Enquiries",
        //   },
    ];

    if (!theme?.config?.hideProductLength) {
        headCells.push({
            id: "InitialRatePeriodMonths" as keyof Data,
            label: "Product Length",
            tooltip: "This shows the length of the product in months.",
        });
    }

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    if (!theme?.config?.hideLender || !theme?.config?.hideChatIcon) {
        headCells.unshift({
            id: "LenderCode",
            numeric: false,
            label: theme?.config?.hideLender ? "Speak with Us" : "Lender",
        });
    }

    if (!theme?.config?.hideMoreInfo) {
        headCells.push({
            id: "MoreInfo",
            label: "More Info",
        });
    }

    if (!theme?.config?.hideEnquireButton) {
        headCells.push({
            id: "Enquire",
            label: "Enquire",
        });
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <Tooltip title={headCell.tooltip} key={headCell.id}>
                        <StyledTableCell
                            scope="col"
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                            variant="head"
                        >
                            <StyledTableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <StyledText fontWeight="600" color="secondary" fontSize="16">
                                    {headCell.label}
                                </StyledText>
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </StyledTableSortLabel>
                        </StyledTableCell>
                    </Tooltip>
                ))}
            </TableRow>
        </TableHead>
    );
};

// const openChatApp = (chatAppApiKey: string) => {
//   window.open(`${config.chatAppUrl}/client?apiKey=${chatAppApiKey}`);
// };

const LenderIcons = styled.img`
    margin: 12px;
    vertical-align: middle;
    cursor: pointer;
`;

const LoadingDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LenderControls = styled.div`
    display: inline-block;
`;

const { REACT_APP_CHATAPP_URL } = process.env;

const MortgageResults: React.FC<MortgageResultsProps> = ({ form }) => {
    const theme = useTheme() as Theme;

    const { apps } = useContext(ClientContext);
    const { isLoading } = useContext(FormContext);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Data>("InitialPayRate");
    const [rows, setRows] = useState<Data[]>([]);
    const [lenderProfile, setLenderProfile] = useState<LenderProfile>({});
    const [apiKey, setApiKey] = useState<string>();
    const [enquireModalOpen, setEnquireModalOpen] = useState(false);
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    //   const { apps } = useContext(ClientContext);
    const { lenders } = useContext(FormContext);

    const contactForm = useForm({
        mode: "onChange",
        resolver: zodResolver(contactFormSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            town: "",
            enquiry: "",
        },
    });

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleContactFormSubmit = async (values: FormData) => {
        try {
            setIsSubmitting(true);
            setFormMessage("");

            await axios.post(`${process.env.REACT_APP_MORTGAGE_URL}/api/teams/sendEmail`, {
                email: theme?.formConfig?.email,
                templateName: "contact-template",
                props: {
                    ...values,
                    theme: theme?.customization?.primaryColor || theme?.customization?.primary,
                    product: `${lenderProfile[selectedRow?.LenderCode]?.Name} £${selectedRow?.InitialMonthlyPayment} Total Fees £${totalFeesCounter(selectedRow)} ${selectedRow?.InitialPayRate}% ${selectedRow?.MortgageClass} ${selectedRow?.InitialRatePeriod}`,
                },
                emailCustomization: theme?.emailCustomization,
            });

            setFormMessage("Thank you for your message, one of the team will be in touch shortly.");
            setIsSubmitSuccessful(true);
        } catch (err) {
            console.error(err);
            setFormMessage(
                "An error encountered while submitting the form. Please try again later."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const totalFeesCounter = (data: any) => {
        return (
            (data?.Bookingfee || 0) +
            (data?.ArrangementFee  || 0) +
            (data?.CHAPSFee || 0) +
            (data?.DisbursementFee || 0)
        ).toLocaleString()
    };

    useEffect(() => {
        if (lenders) {
            setRows(form);
            let lender: LenderProfile = {};
            lenders.forEach((l: Lender) => {
                lender[l.Code] = l;
            });
            setLenderProfile(lender);
        }
    }, [form, lenders]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            contactForm.reset();
            setIsSubmitSuccessful(false);
        }
    }, [isSubmitSuccessful]);

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="mortgage-calculator-results">
                    <colgroup>
                        {(!theme?.config?.hideLender || !theme?.config?.hideChatIcon) && (
                            <col width="15%" />
                        )}
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        {!theme?.config?.hideEnquireButton && <col />}
                    </colgroup>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {rows.length === 0 || isLoading ? (
                            <TableRow sx={{ height: "350px" }}>
                                <TableCell colSpan={8} sx={{ border: "none" }}>
                                    {isLoading ? (
                                        <LoadingDiv>
                                            <h3>LOADING RESULTS...</h3>
                                            <CircularProgress size={120} />
                                        </LoadingDiv>
                                    ) : (
                                        <LoadingDiv>
                                            <h3>NO LENDERS FOUND</h3>
                                        </LoadingDiv>
                                    )}
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <StyledTableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                        // onClick={() => {
                                        //   if (theme?.apps?.chatapp) {
                                        //     window.open(
                                        //       `${REACT_APP_CHATAPP_URL}/client?apiKey=${theme?.apps?.chatapp}`,
                                        //       "_blank"
                                        //     );
                                        //   }
                                        // }}
                                    >
                                        {(!theme?.config?.hideLender ||
                                            !theme?.config?.hideChatIcon) && (
                                            <StyledTableCell
                                                data-label="Lender"
                                                scope="row"
                                                padding="normal"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {!theme?.config?.hideLender && (
                                                    <LenderIcons
                                                        src={
                                                            lenderProfile[row.LenderCode]
                                                                ?.SmallImageSecureUrl
                                                        }
                                                        alt="lender"
                                                    ></LenderIcons>
                                                )}
                                                {!theme?.config?.hideChatIcon &&
                                                    theme?.apps?.chatapp && (
                                                        <IconButton
                                                            disableRipple
                                                            onClick={() => {
                                                                if (
                                                                    isValidUUID(
                                                                        theme?.apps?.chatapp!
                                                                    )
                                                                ) {
                                                                    window.open(
                                                                        `${REACT_APP_CHATAPP_URL}/client?apiKey=${theme?.apps?.chatapp}`,
                                                                        "_blank"
                                                                    );
                                                                } else {
                                                                    window.open(
                                                                        theme?.apps?.chatapp,
                                                                        "_blank"
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {/* <ChatIcon
                                                            width={30}
                                                            height={30}
                                                            color={
                                                                theme.customization?.primaryColor ||
                                                                "#82bba2"
                                                            }
                                                            /> */}
                                                            <img src={Chat} alt="speak-with-us" />
                                                        </IconButton>
                                                    )}
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell data-label="Interest Rate">
                                            <b>{row?.InitialPayRate.toFixed(2)}%</b>
                                        </StyledTableCell>
                                        <StyledTableCell data-label="Mortgage Class">
                                            <b>{row?.MortgageClass}</b>
                                        </StyledTableCell>
                                        <StyledTableCell data-label="Cost">
                                            <b>£{row?.InitialMonthlyPayment}</b>
                                        </StyledTableCell>
                                        <StyledTableCell data-label="Total Fees">
                                            <b>£{totalFeesCounter(row)}</b>
                                        </StyledTableCell>
                                        <StyledTableCell data-label="APRC">
                                            <b>{row?.StandardVariableRate.toFixed(2)}%</b>
                                        </StyledTableCell>
                                        <StyledTableCell data-label="Max LTV">
                                            {" "}
                                            <b>{row?.MaxLTVAvailable}%</b>
                                        </StyledTableCell>
                                        {!theme?.config?.hideProductLength && (
                                            <StyledTableCell data-label="Product Length">
                                                {" "}
                                                <b>{row?.InitialRatePeriodMonths} months</b>
                                            </StyledTableCell>
                                        )}
                                        {!theme?.config?.hideMoreInfo && (
                                            <StyledTableCell data-label="More Info">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    variant="text"
                                                    onClick={() => {
                                                        console.log({
                                                            row,
                                                        });
                                                        setSelectedRow(row);
                                                        setMoreInfoModalOpen(true);
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        textTransform="capitalize"
                                                        color="secondary"
                                                        fontSize={{
                                                            md: 16,
                                                            xs: 12,
                                                        }}
                                                    >
                                                        More Info
                                                    </Typography>
                                                </Button>
                                            </StyledTableCell>
                                        )}
                                        {!theme?.config?.hideEnquireButton && (
                                            <StyledTableCell data-label="Enquire">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    variant="contained"
                                                    disableElevation
                                                    disableRipple
                                                    onClick={() => {
                                                        if (theme?.config?.enquireButtonLink) {
                                                            if (
                                                                isEmail(
                                                                    theme?.config?.enquireButtonLink
                                                                )
                                                            ) {
                                                                window.open(
                                                                    `mailto:${theme?.config?.enquireButtonLink}`
                                                                );
                                                            } else {
                                                                window.open(
                                                                    theme?.config
                                                                        ?.enquireButtonLink,
                                                                    "_blank"
                                                                );
                                                            }
                                                        } else {
                                                            setSelectedRow(row);
                                                            setEnquireModalOpen(true);
                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        textTransform="capitalize"
                                                        color="white"
                                                        fontSize={{
                                                            md: 16,
                                                            xs: 12,
                                                        }}
                                                    >
                                                        Enquire Now
                                                    </Typography>
                                                </Button>
                                            </StyledTableCell>
                                        )}
                                    </StyledTableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={moreInfoModalOpen}
                onClose={() => {
                    setMoreInfoModalOpen(false);
                }}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={moreInfoModalOpen}>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            p: 4,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: {
                                md: "600px",
                                xs: "80%",
                            },
                            borderRadius: "20px",
                        }}
                    >
                        <Typography variant="body1">
                            A mortgage of £{numberWithComma(parseFloat(selectedRow?.LoanAmount))}{" "}
                            payable over {selectedRow?.MortgageTerm} years, initially on a fixed
                            rate for {selectedRow?.InitialRateDuration} years at{" "}
                            {selectedRow?.InitialPayRate}% and then on a variable rate of{" "}
                            {selectedRow?.StandardVariableRate}% for the remaining{" "}
                            {selectedRow?.MortgageTerm - selectedRow?.InitialRateDuration} years
                            would require {selectedRow?.InitialRateDuration * 12} payments of £
                            {numberWithComma(selectedRow?.InitialMonthlyPayment)} and{" "}
                            {(selectedRow?.MortgageTerm - selectedRow?.InitialRateDuration) * 12}{" "}
                            payments of £
                            {numberWithComma(selectedRow?.MonthlyPaymentAfterInitialPeriod)}. The
                            total amount payable would be £
                            {numberWithComma(selectedRow?.TrueCostFullTerm)} and fees (£
                            {totalFeesCounter(selectedRow)}). The overall cost for comparison is{" "}
                            {selectedRow?.AprLenders}% APRC representative.
                        </Typography>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                open={enquireModalOpen}
                onClose={() => {
                    setEnquireModalOpen(false);
                    setFormMessage("");
                    contactForm.reset();
                }}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                closeAfterTransition
                // slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={enquireModalOpen}>
                    <Box
                        sx={{
                            height: "100vh",
                            overflowY: "auto",
                            bgcolor: "background.paper",
                            padding: "32px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setEnquireModalOpen(false);
                                setFormMessage("");
                                contactForm.reset();
                            }}
                            sx={{
                                display: "block",
                                borderRadius: "8px",
                                marginLeft: "auto",
                            }}
                            disableElevation
                            disableRipple
                        >
                            <Typography variant="body1" color="white" textTransform="capitalize">
                                Cancel
                            </Typography>
                        </Button>
                        <Container maxWidth="md" sx={{ marginTop: "20px", marginBottom: "30px" }}>
                            <Typography
                                component="p"
                                textAlign={{
                                    xs: "left",
                                    md: "center",
                                }}
                                marginBottom="10px"
                            >
                                I would&nbsp;
                                {theme.apiKey === "322d980e-8463-47ab-97bb-e7013955a66b" ? (
                                    <>like</>
                                ) : (
                                    <>love</>
                                )}
                                &nbsp;to find out everything about this mortgage
                            </Typography>
                            <Grid container spacing={4}>
                                {!theme?.config?.hideLenderLogoOnFormSubmission ? (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        textAlign={{
                                            xs: "left",
                                            md: "right",
                                        }}
                                    >
                                        <LenderIcons
                                            src={
                                                lenderProfile[selectedRow?.LenderCode]
                                                    ?.SmallImageSecureUrl
                                            }
                                            alt="lender"
                                        ></LenderIcons>
                                    </Grid>
                                ) : null}
                                <Grid
                                    item
                                    xs={12}
                                    md={theme?.config?.hideLenderLogoOnFormSubmission ? 12 : 6}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={1}
                                        style={{
                                            textAlign: `${theme?.config?.hideLenderLogoOnFormSubmission ? "center" : "left"}`,
                                        }}
                                    >
                                        <Typography component="p" fontWeight={700}>
                                            £{selectedRow?.InitialMonthlyPayment} Per Month
                                        </Typography>
                                        <Typography component="p">
                                            Total Fees £{totalFeesCounter(selectedRow)}
                                        </Typography>
                                        <Typography component="p">
                                            {selectedRow?.InitialPayRate}%{" "}
                                            {selectedRow?.MortgageClass}{" "}
                                            {selectedRow?.InitialRatePeriod}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider />
                        <Container maxWidth="md" sx={{ marginTop: "25px" }}>
                            <Typography
                                component="p"
                                textAlign={{
                                    xs: "left",
                                    md: "center",
                                }}
                                marginBottom="20px"
                                fontWeight={700}
                            >
                                Send us your details and one of your specialist will call you
                            </Typography>
                            <FormProvider {...contactForm}>
                                <form
                                    onSubmit={contactForm.handleSubmit(handleContactFormSubmit)}
                                    noValidate
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                control={contactForm.control}
                                                name="firstName"
                                                render={({ field, fieldState }) => (
                                                    <Stack spacing={1}>
                                                        <Typography component="p">
                                                            First Name
                                                        </Typography>
                                                        <CustomTextField fullWidth {...field} />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                control={contactForm.control}
                                                name="lastName"
                                                render={({ field, fieldState }) => (
                                                    <Stack spacing={1}>
                                                        <Typography component="p">
                                                            Last Name
                                                        </Typography>
                                                        <CustomTextField fullWidth {...field} />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                control={contactForm.control}
                                                name="email"
                                                render={({ field, fieldState }) => (
                                                    <Stack spacing={1}>
                                                        <Typography component="p">
                                                            Email Address
                                                        </Typography>
                                                        <CustomTextField fullWidth {...field} />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                control={contactForm.control}
                                                name="phone"
                                                render={({ field, fieldState }) => (
                                                    <Stack spacing={1}>
                                                        <Typography component="p">Phone</Typography>
                                                        <CustomTextField fullWidth {...field} />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                control={contactForm.control}
                                                name="town"
                                                render={({ field, fieldState }) => (
                                                    <Stack>
                                                        <Typography component="p">Town</Typography>
                                                        <CustomTextField fullWidth {...field} />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                control={contactForm.control}
                                                name="enquiry"
                                                render={({ field, fieldState }) => (
                                                    <Stack>
                                                        <Typography component="p">
                                                            Enquiry
                                                        </Typography>
                                                        <CustomTextField
                                                            multiline
                                                            rows={8}
                                                            fullWidth
                                                            {...field}
                                                        />
                                                        {fieldState?.error?.message && (
                                                            <Typography variant="body2" color="red">
                                                                {fieldState.error?.message}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} textAlign={`center`}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                disableElevation
                                                disableRipple
                                                sx={{ borderRadius: 20 }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    color="white"
                                                    textTransform="capitalize"
                                                >
                                                    Send Enquiry
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        {formMessage && (
                                            <Grid item xs={12} textAlign={`center`}>
                                                <Typography variant="body1" sx={{ fontSize: 20 }}>
                                                    {formMessage}
                                                    <Image
                                                        alt="check"
                                                        src="https://d365pq86x330zn.cloudfront.net/717N7711-w8w3-4573-7777-w117N7N10217.png"
                                                        margin="0 8px"
                                                        maxWidth="30px"
                                                    />
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Container>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
export default MortgageResults;
