import Dropdown from "../shared/Dropdown/Dropdown";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@mui/material";
import { FieldValues, UseFormReturn, Controller } from "react-hook-form";
import styled from "styled-components";
import Label from "../shared/Label/Label";
import config from "./../../assets/data/config";
import { lenders } from "../../assets/data/lenders";
import Button from "../shared/Button/Button";
import StyledText from "../shared/Text/Text";
import { useContext, useEffect, useRef } from "react";
import ClientContext from "../../context/ClientContext";

type FilterProps = {
  className?: string;
  methods: UseFormReturn<FieldValues, any>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${config.device.mobileS}) and (max-width: ${config.device
      .tablet}) {
    justify-content: space-evenly;
  }

  @media (max-width: ${config.device.mobileM}) {
    display: block;
  }
`;

const ApplyFilterDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const Filter: React.FC<FilterProps> = ({ className, methods, setOpen }) => {
  const { customization } = useContext(ClientContext);
  const { register, control, getValues, setValue } = methods;
  const prevLenders = useRef<string>("");
  const prevRateType = useRef<boolean[]>([]);
  const prevRatePeriod = useRef<boolean[]>([]);
  const applyFilters = useRef<boolean>(false);

  useEffect(() => {
    if (!prevLenders.current) {
      prevLenders.current = getValues().lenders;
    }
    if (prevRateType.current.length === 0) {
      prevRateType.current = [...getValues().rateType];
    }
    if (prevRatePeriod.current.length === 0) {
      prevRatePeriod.current = [...getValues().ratePeriod];
    }
    return () => {
      if (!applyFilters.current) {
        setValue("lenders", prevLenders.current);
        setValue("rateType", prevRateType.current);
        setValue("ratePeriod", prevRatePeriod.current);
      }
    };
  }, [getValues, setValue]);

  const saveFilters = () => {
    applyFilters.current = true;
    setOpen(false);
  };

  return (
    <div className={`mortgage-filters ${className}`}>
      <FilterGroup>
        <Dropdown
          name="lenders"
          label="Lender"
          options={lenders.map((lender) => ({
            label: lender,
            value: lender,
          }))}
          position="horizontal"
          register={register}
        />
      </FilterGroup>
      <FilterGroup>
        <CheckboxGroup>
          <FormControl
            sx={{ paddingY: 3 }}
            component="fieldset"
            variant="standard"
          >
            <Tooltip
              title={
                <Typography fontSize={12}>
                  There are different types of rate offered:
                  <br />
                  <br />
                  Fixed: meaning they remain constant for the duration of the
                  loan term.
                  <br />
                  <br />
                  Variable, discount or tracker: meaning they fluctuate based on
                  market conditions and the Bank of England's base rate.
                </Typography>
              }
            >
              <Typography variant="h6">
                <Label>Rate Type</Label>
              </Typography>
            </Tooltip>
            <FormGroup>
              <Controller
                name="rateType[0]"
                control={control}
                render={({ field }) => (
                  // cleanup sx
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().rateType?.[0]}
                        name="Fixed"
                        sx={{
                          "&.Mui-checked": {
                            color: "primary.main",
                          },
                        }}
                      />
                    }
                    label="Fixed"
                    {...field}
                  />
                )}
              />
              <Controller
                name="rateType[1]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().rateType?.[1]}
                        name="Tracker"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="Tracker"
                    {...field}
                  />
                )}
              />
              <Controller
                name="rateType[2]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().rateType?.[2]}
                        name="Discount"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="Discount"
                    {...field}
                  />
                )}
              />
              <Controller
                name="rateType[3]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().rateType?.[3]}
                        name="Variable"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="Variable"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </CheckboxGroup>
        <CheckboxGroup>
          <FormControl
            sx={{ paddingY: 3 }}
            component="fieldset"
            variant="standard"
          >
            <Tooltip title="The product is also the initial term or “deal” length. ">
              <Typography variant="h6">
                <Label>Product Length</Label>
              </Typography>
            </Tooltip>
            <FormGroup>
              <Controller
                name="ratePeriod[0]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().ratePeriod?.[0]}
                        name="2-years"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="2 Years"
                    {...field}
                  />
                )}
              />
              <Controller
                name="ratePeriod[1]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().ratePeriod?.[1]}
                        name="3-years"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="3 Years"
                    {...field}
                  />
                )}
              />
              <Controller
                name="ratePeriod[2]"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues().ratePeriod?.[2]}
                        name="5-years"
                        sx={{
                          "&.Mui-checked": {
                            color: customization.primaryColor || "primary.main",
                          },
                        }}
                      />
                    }
                    label="5 Years"
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </CheckboxGroup>
      </FilterGroup>
      <ApplyFilterDiv>
        {/* CLEANUP SX */}
        <Button
          type="button"
          variant="contained"
          size="large"
          color="primary"
          onClick={saveFilters}
          sx={{ width: 1 / 3 }}
        >
          <StyledText fontSize="16" color="secondary" fontWeight="600">
            Apply
          </StyledText>
        </Button>
      </ApplyFilterDiv>
    </div>
  );
};

const StyledFilter = styled(Filter)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${config.device.mobileS}) and (max-width: ${config.device
      .tablet}) {
    width: auto;
  }
`;

export default StyledFilter;
