import config from "../data/config";
import { Theme } from "../../interfaces";
import buildingImage from "../images/Building_Images.png";

export const themes: Theme[] = [
  {
    code: "ruby",
    customization: {
      primary: {
        main: config.themes.ruby.primary.main,
        dark: config.themes.ruby.primary.dark,
        light: config.themes.ruby.primary.light,
        contrastText: config.themes.ruby.primary.contrastText,
      },
      secondary: {
        main: config.themes.ruby.secondary.main,
        contrastText: config.themes.ruby.secondary.contrastText,
      },
      common: {
        white: config.themes.ruby.common.white,
        black: config.themes.ruby.common.black,
      },
      action: {
        hover: config.themes.ruby.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
  {
    code: "royal",
    customization: {
      primary: {
        main: config.themes.royal.primary.main,
        dark: config.themes.royal.primary.dark,
        light: config.themes.royal.primary.light,
        contrastText: config.themes.royal.primary.contrastText,
      },
      secondary: {
        main: config.themes.royal.secondary.main,
        contrastText: config.themes.royal.secondary.contrastText,
      },
      common: {
        white: config.themes.royal.common.white,
        black: config.themes.royal.common.black,
      },
      action: {
        hover: config.themes.royal.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
  {
    code: "hazelnut",
    customization: {
      primary: {
        main: config.themes.hazelnut.primary.main,
        dark: config.themes.hazelnut.primary.dark,
        light: config.themes.hazelnut.primary.light,
        contrastText: config.themes.hazelnut.primary.contrastText,
      },
      secondary: {
        main: config.themes.hazelnut.secondary.main,
        contrastText: config.themes.hazelnut.secondary.contrastText,
      },
      common: {
        white: config.themes.hazelnut.common.white,
        black: config.themes.hazelnut.common.black,
      },
      action: {
        hover: config.themes.hazelnut.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
  {
    code: "jade",
    customization: {
      primary: {
        main: config.themes.jade.primary.main,
        dark: config.themes.jade.primary.dark,
        light: config.themes.jade.primary.light,
        contrastText: config.themes.jade.primary.contrastText,
      },
      secondary: {
        main: config.themes.jade.secondary.main,
        contrastText: config.themes.jade.secondary.contrastText,
      },
      common: {
        white: config.themes.jade.common.white,
        black: config.themes.jade.common.black,
      },
      action: {
        hover: config.themes.jade.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
  {
    code: "basic",
    customization: {
      primary: {
        main: config.themes.basic.primary.main,
        dark: config.themes.basic.primary.dark,
        light: config.themes.basic.primary.light,
        contrastText: config.themes.basic.primary.contrastText,
      },
      secondary: {
        main: config.themes.basic.secondary.main,
        contrastText: config.themes.basic.secondary.contrastText,
      },
      common: {
        white: config.themes.basic.common.white,
        black: config.themes.basic.common.black,
      },
      action: {
        hover: config.themes.basic.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
  {
    code: "custom",
    customization: {
      primary: {
        main: config.themes.ruby.primary.main,
        dark: config.themes.ruby.primary.dark,
        light: config.themes.ruby.primary.light,
        contrastText: config.themes.ruby.primary.contrastText,
      },
      secondary: {
        main: config.themes.ruby.secondary.main,
        contrastText: config.themes.ruby.secondary.contrastText,
      },
      common: {
        white: config.themes.ruby.common.white,
        black: config.themes.ruby.common.black,
      },
      action: {
        hover: config.themes.ruby.action.hover,
      },
    },
    images: {
      buildingImage,
    },
  },
];
