import React from "react";
import { Lender } from "./../interfaces/lenders";
import { RunSourceResult } from "./../interfaces/runSource";

const FormContext = React.createContext({
    ltvRatio: 0,
    setLtvRatio: (ltv: number) => {},
    form: [] as RunSourceResult[],
    setForm: (f: RunSourceResult[]) => {},
    lenders: [] as Lender[],
    setLenders: (l: Lender[]) => {},
    searched: false,
    setSearched: (s: boolean) => {},
    isLoading: false,
    setIsLoading: (f: any) => {},
    defaultmortgagetype: "Purchase",
    defaultpaymentmethod: "Repayment",
    defaultterm: 25,
    defaultpropertyvalue: "500000",
    defaultloan: "300000",
    defaultrent: 2250,
    defaultownership: "Personal Name",
});

export default FormContext;
