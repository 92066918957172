import styled from 'styled-components';

interface ImageProps {
  src: string;
  alt: string;
  borderRadius?: string;
  boxShadow?: string;
  objectFit?: string;
  margin?: string;
  maxWidth?: string;
}

const StyledImage = styled.img<ImageProps>`
  width: 100%;
  height: auto;
  border-radius: ${(props) => props.borderRadius || '0px'};
  box-shadow: ${(props) => props.boxShadow || 'none'};
  object-fit: ${(props) => props.objectFit || 'cover'};
  margin: ${(props) => props.margin || '0'};
  max-width: ${(props) => props.maxWidth || '100%'};
`;

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  borderRadius,
  boxShadow,
  objectFit,
  margin,
  maxWidth,
}) => (
  <StyledImage
    src={src}
    alt={alt}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    objectFit={objectFit}
    margin={margin}
    maxWidth={maxWidth}
  />
);

export default Image;