import styled from "styled-components";

export type TextProps = {
  className?: string;
  id?: string;
  fontWeight?: string;
  color?: string;
  fontSize?: string;
  children?: React.ReactNode;
};

const Text = styled.span<TextProps>`
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  color: ${({ theme, color }) =>
    color === "primary"
      ? theme.customization.primary.contrastText
      : color === "secondary"
        ? theme.customization.secondary.contrastText
        : "#000"};
  font-size: ${({ fontSize }) =>
    Number(fontSize) ? `${fontSize}px` : fontSize || "1em"};
`;

const StyledText: React.FC<TextProps> = ({
  className,
  id,
  children,
  ...textProps
}) => {
  return (
    <Text className={className} id={id} {...textProps}>
      {children}
    </Text>
  );
};

export default StyledText;
