import {
    Theme as MuiTheme,
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import config from "./assets/data/config";
import { themes } from "./assets/themes";
import * as MuiThemes from "./assets/themes/material-ui";
import { commsApi } from "./axios";
import Calculator from "./containers/Calculator/Calculator";
import ClientContext from "./context/ClientContext";
import FormContext from "./context/FormContext";
import { ClientData, MPStoreApps, Theme } from "./interfaces";
import { Lender } from "./interfaces/lenders";
import { RunSourceResult } from "./interfaces/runSource";
import { initializeGoogleAnalytics } from "./utils/ga";
import AvenirRegularBase64 from "./assets/fonts/Avenir/Avenir-Regular-Base64";
import AvenirBoldBase64 from "./assets/fonts/Avenir/Avenir-Bold-Base64";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Avenir";
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local("Avenir"),
            url(data:font/woff2;base64,${AvenirRegularBase64}) format("truetype");
    }

    @font-face {
        font-family: "Avenir";
        font-style: normal;
        font-display: swap;
        font-weight: 700;
        src: local("Avenir"),
            url(data:font/woff2;base64,${AvenirBoldBase64}) format("truetype");
    }

    body {
        background: #FFF;
        // background-image: url(${({ theme }: { theme: Theme }) => theme?.images?.buildingImage});
        // background-repeat: no-repeat;
        // background-attachment: fixed;
        // background-position: bottom;
        // background-size: 100% 50%;
    }
`;

interface AppProps {
    apiKey: string;
    appConfig: any;
}

const App: React.FC<AppProps> = ({ apiKey, appConfig }: AppProps) => {
    const [theme, setTheme] = useState<Theme>();
    const [muiTheme, setMuiTheme] = useState<MuiTheme>();
    const [ltvRatio, setLtvRatio] = useState<number>(60);
    const [form, setForm] = useState<RunSourceResult[]>([]);
    const [lenders, setLenders] = useState<Lender[]>([]);
    const [searched, setSearched] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apps, setApps] = useState<MPStoreApps>();
    const [logo, setLogo] = useState<string>("");
    const value = {
        ltvRatio,
        setLtvRatio,
        form,
        setForm,
        lenders,
        setLenders,
        isLoading,
        setIsLoading,
        searched,
        setSearched,
        defaultmortgagetype: appConfig.defaultmortgagetype
            ? appConfig.defaultmortgagetype === "HomeMover"
                ? "Home Mover"
                : appConfig.defaultmortgagetype
            : "Purchase",
        defaultpaymentmethod: appConfig.defaultpaymentmethod || "Repayment",
        defaultterm: appConfig.defaultterm || 25,
        defaultpropertyvalue: appConfig.defaultpropertyvalue || "500000",
        defaultloan: Math.min(
            parseInt(appConfig.defaultloan || 300000),
            parseInt(appConfig.defaultpropertyvalue || "500000")
        ).toString(),
        defaultrent: appConfig.defaultrent || 2250,
        defaultownership:
            appConfig.defaultownership === "Ltd Company" ? "Ltd Company" : "Personal Name",
        defaultreasonformortgage: "Purchase",
    };
    const client = { apps, customization: { logo }, appConfig };

    const loadFonts = (fontFamily: string, fontWeight: number, fontUrl: string, type: string) => {
        const style = document.createElement("style");
        style.textContent = `
              @font-face {
                font-family: '${fontFamily}';
                src: url('${fontUrl}') format(${type});
                font-weight: ${fontWeight};
              }
            `;
        document.head.appendChild(style);
    };

    const getClientData = useCallback(async () => {
        const checkAndAddGTM = () => {
            if (apiKey === "0580634f-2a4c-4f46-bfc6-7b0663f39976") {
                const script = document.createElement("script");
                script.async = true;
                script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HFQKCQD');
        `;
                document.head.appendChild(script);
            }
        };

        if (apiKey) {
            const { data: clientData }: { data: ClientData } = await commsApi.post(
                "/api/calculator/client",
                { apiKey }
            );

            if (clientData && clientData.theme?.code) {
                if (clientData.theme?.code !== "custom") {
                    setMuiTheme((MuiThemes as any)[clientData.theme?.code]);
                    setTheme({
                        ...themes.find((t: Theme) => t.code === clientData.theme.code)!,
                        apiKey,
                    });
                } else {
                    if (clientData.customization.fontFamily) {
                        await Promise.all(
                            config.fontTypes.map((type: string) => {
                                return config.fontStyles.map(async (style: any) => {
                                    const fontUrl = `${process.env.REACT_APP_LIVE_URL}/assets/fonts/${clientData.customization.fontFamily}/${clientData.customization.fontFamily}-${style.name}${type}`;

                                    const data = await fetch(fontUrl, {
                                        method: "HEAD",
                                    });
                                    if (data.ok) {
                                        loadFonts(
                                            `${clientData.customization.fontFamily}`,
                                            style.weight,
                                            fontUrl,
                                            type === ".otf" ? "opentype" : "truetype"
                                        );
                                    }
                                });
                            })
                        );
                    }

                    const custom = createTheme({
                        typography: {
                            fontFamily: clientData.customization.fontFamily
                                ? `${clientData.customization.fontFamily}`
                                : "Montserrat",
                            fontWeightLight: 300,
                            fontWeightRegular: 400,
                            fontWeightBold: 700,
                            h3: {
                                fontFamily: clientData.customization.fontFamily
                                    ? `${clientData.customization.fontFamily}`
                                    : "Montserrat",
                                fontWeight: 600,
                                fontSize: "28px",
                            },
                            h4: {
                                fontFamily: clientData.customization.fontFamily
                                    ? `${clientData.customization.fontFamily}`
                                    : "Montserrat",
                            },
                            h5: {
                                fontFamily: clientData.customization.fontFamily
                                    ? `${clientData.customization.fontFamily}`
                                    : "Montserrat",
                                fontWeight: 600,
                                fontSize: "1rem",
                            },
                            h6: {
                                fontFamily: clientData.customization.fontFamily
                                    ? `${clientData.customization.fontFamily}`
                                    : "Montserrat",
                            },
                        },
                        palette: {
                            primary: {
                                main:
                                    clientData.customization?.primaryColor ||
                                    config.themes.ruby.primary.main,
                            },
                            secondary: {
                                main:
                                    clientData.customization?.secondaryColor ||
                                    config.themes.ruby.secondary.main,
                            },
                        },
                    });
                    setMuiTheme(custom);
                    const customTheme = themes
                        .map((t: Theme) => {
                            if (t.code === "custom") {
                                return {
                                    ...t,
                                    code: t.code,
                                    customization: {
                                        ...t.customization,
                                        primary: {
                                            ...t.customization?.primary,
                                            main: clientData.customization.primaryColor!,
                                        },
                                        secondary: {
                                            ...t.customization?.secondary,
                                            main: clientData.customization.secondaryColor!,
                                        },
                                    },
                                    formConfig: clientData?.formConfig,
                                    emailCustomization: clientData?.emailCustomization,
                                    apps: clientData?.apps,
                                    config: clientData?.config,
                                    googleAnalyticsTrackingId:
                                        clientData?.googleAnalyticsTrackingId,
                                };
                            }
                            return null;
                        })
                        .filter(Boolean);
                    if (customTheme && customTheme.length > 0) {
                        setTheme({
                            ...((customTheme[0] || themes[0]) as any),
                            apiKey,
                        });
                    }
                }

                if (
                    Array.isArray(clientData.googleAnalyticsTrackingId) &&
                    clientData.googleAnalyticsTrackingId?.length
                ) {
                    initializeGoogleAnalytics(clientData?.googleAnalyticsTrackingId);
                }
            }
            if (clientData && clientData.apps) {
                setApps(clientData.apps);
            }
            if (clientData?.customization) {
                setLogo(clientData.customization?.logo || "");
            }

            checkAndAddGTM();
        }
        //this
    }, [apiKey]);

    useEffect(() => {
        getClientData();
    }, [getClientData]);

    useEffect(() => {
        if (theme?.config?.globalFontSize) {
            const styleEFS = document.createElement("style");
            styleEFS.textContent = `
                * { font-size: ${theme?.config?.globalFontSize}!important; }
            `;
            document.head.appendChild(styleEFS);
        }
    }, [theme]);

    return theme && muiTheme ? (
        <>
            {apiKey === "0580634f-2a4c-4f46-bfc6-7b0663f39976" && (
                <Helmet>
                    <noscript>
                        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5HFQKCQD"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                    </noscript>
                </Helmet>
            )}
            <ClientContext.Provider value={client}>
                <FormContext.Provider value={value}>
                    <MuiThemeProvider theme={muiTheme}>
                        <ThemeProvider theme={theme}>
                            <Calculator />
                            <GlobalStyle theme={theme} />
                        </ThemeProvider>
                    </MuiThemeProvider>
                </FormContext.Provider>
            </ClientContext.Provider>
        </>
    ) : (
        <></>
    );
};

export default App;
