import React from "react";
import styled from "styled-components";

type StyledProps = {
  className?: string;
  ref?: any;
  children?: React.ReactNode;
};

const MortgageSearchContainer: React.FC<StyledProps> = React.forwardRef<
  any,
  StyledProps
>(({ className, children }, ref) => (
  <div className={`mortgage-search-container ${className}`} ref={ref}>
    {children}
  </div>
));

const StyledMortgageSearchContainer = styled(MortgageSearchContainer)`
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  background: #fff;
`;

export default StyledMortgageSearchContainer;
