import styled from "styled-components";

type StyledProps = {
  className?: string;
  children?: React.ReactNode;
};

const FooterContainer: React.FC<StyledProps> = ({ className, children }) => (
  <div className={`footer-container ${className}`}>{children}</div>
);

const StyledFooterContainer = styled(FooterContainer)`
  display: flex;
  justify-content: center;
`;

export default StyledFooterContainer;
