import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal, { ModalProps } from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Separator from "../Separator/Separator";
import { createStyles } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 374px)": createStyles({
    width: 280,
  }),
};

const Div = styled.div`
  padding: 10px 0;
`;

const CustomModal: React.FC<ModalProps> = ({
  className,
  id,
  title,
  open,
  onClose,
  children,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Div>
            <Typography id="transition-modal-title" variant="h5" component="h2">
              {title}
            </Typography>
          </Div>
          <Separator />
          <Div>{children}</Div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
