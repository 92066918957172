import _ from "lodash";
import { RunSourceResult } from "./../interfaces/runSource";

const formatToTwenty7tecAPIValues = (text: string) => text.replaceAll(" ", "_");

const formatRateTypes = (rateTypeFilters: boolean[]) => ({
    Capped: "Ignore",
    LiborLinked: "Ignore",
    Discount: rateTypeFilters[2] ? "No_Filter" : "Ignore",
    Tracker: rateTypeFilters[1] ? "No_Filter" : "Ignore",
    Fixed: rateTypeFilters[0] ? "No_Filter" : "Ignore",
    Variable: rateTypeFilters[3] ? "No_Filter" : "Ignore",
});

export const formatRunSourceArguments = (data: any) => {
    // const IncludedLenders =
    //   data.lenders !== "All"
    //     ? {
    //         IncludedLenders: data.IncludedLenders.map((lender: string) => ({
    //           string: lender,
    //         })),
    //       }
    //     : null;
    const parsedMortgagePurpose = JSON.parse(data.mortgagePurpose);

    let productTermPeriodFromMonths = 0;
    const ratePeriod = data.ratePeriod;

    if (ratePeriod.filter(Boolean).length !== 3) {
        if (ratePeriod[0]) {
            productTermPeriodFromMonths = 24;
        } else if (ratePeriod[1]) {
            productTermPeriodFromMonths = 36;
        } else if (ratePeriod[2]) {
            productTermPeriodFromMonths = 60;
        }
    }

    return [
        {
            ...parsedMortgagePurpose,
            Term: data.mortgageTerm,
            ExpectedValuation: data.propertyValue,
            LoanRequired: data.loanAmount,
            ...((parsedMortgagePurpose?.MortgageType === "Buy_To_Let" ||
                parsedMortgagePurpose?.MortgageType === "Let_To_Buy") && {
                BuyToLetDetails: {
                    ExpectedRentalIncomePerMonth: data.rentalIncome,
                    ...(parsedMortgagePurpose.BuyToLetDetails || {}),
                },
            }),
            // NearMissesDetails: data?.NearMissesDetails,
            // ReasonForMortgage: data.mortgagePurpose,
            // MortgageType: "Standard",
            PaymentMethod: formatToTwenty7tecAPIValues(data.paymentMethod),
            // SearchMatchingType: "Include_Near_Misses",
            MortgageClass: formatRateTypes(data.rateType),
            Filters: {
                ...(parsedMortgagePurpose.Filters || {}),
                SortResultsByColumn: {
                    Direction: "Ascending",
                    Column: "InitialPayRate",
                },
                ...(productTermPeriodFromMonths && {
                    ProductTermPeriodToMonths: productTermPeriodFromMonths,
                }),
                IncludedLenders: data.IncludedLenders,
            },
            NumberOfItems: 3000,
        },
    ];
    // return getRatePeriodInMonths(data.ratePeriod).map((months: number) => {
    //     return {
    //         Term: data.mortgageTerm,
    //         ExpectedValuation: data.propertyValue,
    //         LoanRequired: data.loanAmount,
    //         ReasonForMortgage: data.mortgagePurpose,
    //         MortgageType: "Standard",
    //         PaymentMethod: formatToTwenty7tecAPIValues(data.paymentMethod),
    //         SearchMatchingType: "Include_Near_Misses",
    //         NearMissesDetails: {
    //             MaximumLTVBuffer: 95,
    //         },
    //         MortgageClass: formatRateTypes(data.rateType),
    //         Filters: {
    //             SortResultsByColumn: {
    //                 Direction: "Ascending",
    //                 Column: "InitialPayRate",
    //             },
    //             Retention: "Ignore",
    //             // ProductTermPeriodToMonths: months,
    //             HelpToBuy: "Ignore",
    //             ...IncludedLenders,
    //         },
    //         // NumberOfItems: 10,
    //     };
    // });
    // return {
    //     form: {
    //         mortgageRequirements: {
    //             mortgageType: data.mortgagePurpose,
    //             firstHomeValue: data.propertyValue,
    //             term: data.mortgageTerm,
    //             paymentMethod: formatToTwenty7tecAPIValues(data.paymentMethod),
    //             rentalIncome: data.rentalIncome || 0,
    //             applicantType: formatToTwenty7tecAPIValues("First Time Buyer"),
    //             // applicantType: formatToTwenty7tecAPIValues(data.applicantType),
    //         },
    //         loan: {
    //             amount: data.loanAmount,
    //         },
    //         isFormSubmitted: true,
    //     },
    //     filters: {
    //         rateType: formatRateTypes(data.rateType),
    //         ratePeriod: data.ratePeriod,
    //         lenders: data.lenders,
    //     },
    // };
};

export const filterRunSourceData = (mortgageTerm: number, data: any, form: any, resultCount: number) => {
    const rsData = _.flatten<RunSourceResult>(data);
    return filterIllustration(mortgageTerm, rsData, form)
        .sort((a: any, b: any) => a.InitialPayRate - b.InitialPayRate)
        .slice(0, resultCount);
};

const getInitialRateDuration = (months: number) => {
    if (months <= 29) return 2;
    if (months <= 40) return 3;
    if (months <= 68) return 5;

    return 10;
};

const filterIllustration = (
    mortgageTerm: number,
    data: RunSourceResult[],
    form: any
): RunSourceResult[] => {
    const { ratePeriod, lenders } = form;
    return _.uniqBy(
        data
            .filter((rs: any) => (lenders !== "All" ? rs.LenderName === lenders : true))
            // .filter((rs: RunSourceResult) => filterByRateType(rateType).includes(rs.MortgageClass))
            .filter((rs: RunSourceResult) =>
                getRatePeriodInMonths(ratePeriod).includes(rs.InitialRatePeriodMonths)
            )
            .map((rs: RunSourceResult) => ({
                ...rs,
                LoanAmount: form.loanAmount,
                MortgageTerm: mortgageTerm,
                InitialRateDuration: getInitialRateDuration(rs.InitialRatePeriodMonths),
                InitialMonthlyPayment: Math.ceil(+rs.InitialMonthlyPayment).toLocaleString(),
                FeesTotal: rs.FeesTotal.toLocaleString(),
            })),
        "LenderName"
    );
};

// const filterByRateType = (rateType: boolean[]) => {
//     const rateTypesArr: string[] = [];
//     rateType.forEach((rt: boolean, index: number) => {
//         if (rt) {
//             switch (index) {
//                 case 0:
//                     rateTypesArr.push("Fixed")
//                     break;
//                 case 1:
//                     rateTypesArr.push("Tracker");
//                     break;
//                 case 2:
//                     rateTypesArr.push("Discount");
//                     break;
//                 case 3:
//                     rateTypesArr.push("Variable");
//                     break;
//                 default:
//                     break;
//             }
//         }
//     })
//     return rateTypesArr;
// }

const getRatePeriodInMonths = (ratePeriod: boolean[]) => {
    const monthsArr: number[] = [];
    ratePeriod.forEach((rp: boolean, index: number) => {
        if (rp) {
            switch (index) {
                case 0:
                    monthsArr.push(..._.range(0, 27));
                    break;
                case 1:
                    monthsArr.push(..._.range(27, 41));
                    break;
                case 2:
                    monthsArr.push(..._.range(55, 66));
                    break;
                default:
                    break;
            }
        }
    });
    return monthsArr;
};
