import { UseFormRegister, FieldValues } from "react-hook-form";
import styled from "styled-components";
import { Pound } from "@styled-icons/boxicons-regular/Pound";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";

type LabelProps = {
  disabled?: boolean;
};

type TextFieldProps = {
  className?: string;
  id?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  label?: string;
  value?: string;
  icon?: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  onBlur?: any;
  errorMessage?: string;
  errors?: any;
  position?: string;
  max?: any;
  min?: any;
  register: UseFormRegister<FieldValues>;
  onInput?: any;
  tooltip?: React.ReactNode;
};

const Input = styled.input`
  font-size: 16px;
  padding: 8px;
  margin: 10px 0;
  box-sizing: border-box;
  background: #efefef;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  text-indent: 20px;
  width: 100%;

  &:focus {
    outline: none;
    background: linear-gradient(180deg, #efefef 0%, #d9d9d9 100%);
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Div = styled.div<LabelProps>`
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282626;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
`;

const Span = styled.span`
  width: 100%;
  white-space: nowrap;
`;

const PoundIcon = styled(Pound)`
  position: absolute;
  bottom: 25%;
  left: 5px;
`;

const TextField: React.FC<TextFieldProps> = ({
  className,
  id,
  name,
  label,
  register,
  errors,
  tooltip = "",
  ...textFieldProps
}) => {
  return (
    <Div className={className}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" marginRight="5px">
          <Span>{label}</Span>
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="right-start">
            <Box
              width={16}
              height={16}
              borderRadius={8}
              sx={{ backgroundColor: "primary.main" }}
            >
              <Typography
                lineHeight="1"
                variant="body2"
                color="white"
                textAlign={`center`}
              >
                ?
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Input
        id={name}
        {...register(name)}
        {...textFieldProps}
        style={{
          border: errors && errors[name] && "1px solid red",
        }}
      />
      {/* {errors && errors[name] && <ErrorText>{errorMessage}</ErrorText>} */}
      <PoundIcon size="18" />
    </Div>
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>`
  flex-direction: ${({ position }) =>
    position === "vertical" ? "column" : "row"};
  align-items: ${({ position }) => position === "vertical" && "start"};
`;

export default StyledTextField;
