import styled from "styled-components";

type SeparatorProps = {
  className?: string;
  width?: string;
  margin?: string;
};

const Separator = styled.div<SeparatorProps>`
  border: 1px solid #999;
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin};
  height: 100%;
`;

export const SeparatorDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 16px 20px;
`;

export const SeparatorTitle = styled.div`
  color: ${({ theme }) =>
    theme.customization.primary.contrastText || "#ff385c"};
`;

const CustomSeparator: React.FC<SeparatorProps> = ({
  className,
  ...separatorProps
}) => {
  return <Separator className={className} {...separatorProps} />;
};

export default CustomSeparator;
