import React from "react";
import styled from "styled-components";

type StyledProps = {
  className?: string;
  ref?: any;
  children?: React.ReactNode;
};

const MortgageResultsContainer: React.FC<StyledProps> = React.forwardRef<
  any,
  StyledProps
>(({ className, children }, ref) => (
  <div className={`mortgage-results-container ${className}`} ref={ref}>
    {children}
  </div>
));

const StyledMortgageResultsContainer = styled(MortgageResultsContainer)`
  display: flex;
  flex-direction: row;
  background: #fff;
  flex: 1;
`;

export default StyledMortgageResultsContainer;
