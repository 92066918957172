import styled from "styled-components";
import FormContainer from "../../containers/FormContainer/FormContainer";
import Form from "../Form/Form";

type StyledProps = {
  className?: string;
  apiKey?: string;
};

const MortgageSearch: React.FC<StyledProps> = ({ className, apiKey }) => {
  return (
    <div className={`mortgage-search ${className}`}>
      <FormContainer>
        <Form apiKey={apiKey} />
      </FormContainer>
    </div>
  );
}

const StyledMortgageSearch = styled(MortgageSearch)`
  padding: 8px 0;
`;

export default StyledMortgageSearch;
