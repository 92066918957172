import { useContext, useEffect } from "react";
import { useWatch } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import config from "../../assets/data/config";
import InputContainer from "../../containers/InputContainer/InputContainer";
import FormContext from "../../context/FormContext";
import Dropdown from "../shared/Dropdown/Dropdown";
import StyledSlider from "../shared/Slider/Slider";
import TextField from "../shared/TextField/TextField";

import { Typography } from "@mui/material";
import { Theme } from "../../interfaces";
import { convertToInt } from "../../utils/config";
import { getBTLBrokerMortgagePurpose, getDefaultMortgagePurpose } from "./config";

type RequirementsProps = {
    className?: string;
    methods: any;
};

const Requirements: React.FC<RequirementsProps> = ({ className, methods }) => {
    const {
        register,
        formState: { errors },
        control,
        setValue,
    } = methods;
    const { setLtvRatio } = useContext(FormContext);

    const theme = useTheme() as Theme;

    const paymentMethod = [
        {
            label: "Repayment",
            value: "Repayment",
            disabled: theme?.config?.hideRepayment,
        },
        {
            label: "Interest Only",
            value: "Interest_Only",
            disabled: theme?.config?.hideInterestOnly,
        },
    ];

    const mortgageType = useWatch({
        control,
        name: "mortgagePurpose",
        //defaultValue: JSON.stringify({ReasonForMortgage: 'Purchase', MortgageType: 'Standard'}),
    });

    const mortgageTerm = useWatch({
        control,
        name: "mortgageTerm",
    });

    const propertyValue = useWatch({
        control,
        name: "propertyValue",
    });

    const loanAmount = useWatch({
        control,
        name: "loanAmount",
    });

    const checkValidValues = () => {
        if (+convertToInt(loanAmount) > +convertToInt(propertyValue)) {
            var value = propertyValue.replace(/,/g, "").replace(/\D/g, "");
            var formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setValue("loanAmount", formattedValue);
        }
    };

    useEffect(() => {
        if (
            convertToInt(loanAmount) / convertToInt(propertyValue) >= 0 &&
            convertToInt(loanAmount) / convertToInt(propertyValue) <= 1
        ) {
            setLtvRatio(Math.ceil((convertToInt(loanAmount) / convertToInt(propertyValue)) * 100));
        } else {
            setLtvRatio(0);
        }
    }, [propertyValue, loanAmount, setLtvRatio]);
    const { defaultmortgagetype, defaultpaymentmethod } = useContext(FormContext);

    return (
        <div className={`mortgage-requirements ${className}`}>
            <InputContainer>
                <Dropdown
                    name="paymentMethod"
                    label="Mortgage Repayment Type"
                    options={paymentMethod.filter((item) => !item.disabled)}
                    position="vertical"
                    register={register}
                    tooltip={
                        <Typography fontSize={12}>
                            There are different methods of payment:
                            <br />
                            <br />
                            Repayment Mortgage: This is the most common. You pay back capital and
                            interest at the same time.
                            <br />
                            <br />
                            Interest Only: You only pay the interest on the amount you borrow.
                        </Typography>
                    }
                />
            </InputContainer>
            <InputContainer>
                <Dropdown
                    name="mortgagePurpose"
                    label="Mortgage Purpose"
                    options={(theme?.apiKey === "bbef0922-77fa-44bd-8200-a8386e0d4223a" ||
                    theme?.apiKey === "5f064283-7fc3-4a2d-96a3-69390702e986"
                        ? getBTLBrokerMortgagePurpose(theme)
                        : getDefaultMortgagePurpose(theme)
                    ).filter((item) => !item.disabled)}
                    position="vertical"
                    register={register}
                    tooltip={
                        <Typography fontSize={12}>The reason you require the mortgage.</Typography>
                    }
                />
            </InputContainer>
            <InputContainer>
                <StyledSlider
                    name="mortgageTerm"
                    label="Mortgage Term"
                    value={mortgageTerm}
                    valueLabel={
                        mortgageType && JSON.parse(mortgageType)?.MortgageType === "Bridging_Loan"
                            ? "Months"
                            : "Years"
                    }
                    valueLabelDisplay="off"
                    min={
                        mortgageType && JSON.parse(mortgageType)?.MortgageType === "Bridging_Loan"
                            ? 1
                            : 10
                    }
                    max={
                        mortgageType && JSON.parse(mortgageType)?.MortgageType === "Bridging_Loan"
                            ? 60
                            : 40
                    }
                    position="vertical"
                    methods={methods}
                    sx={{ color: "#ff385c" }}
                    tooltip={
                        <Typography fontSize={12}>
                            The length of time you agree to make regular payments toward repaying
                            the mortgage.
                        </Typography>
                    }
                />
                {/* cleanup sx */}
            </InputContainer>
            <InputContainer>
                <TextField
                    name="propertyValue"
                    type="text"
                    label="Property Value"
                    icon="£"
                    defaultValue="500,000"
                    required
                    position="vertical"
                    onBlur={() => checkValidValues()}
                    register={register}
                    errors={errors}
                    onInput={(ev: any) => {
                        var value = ev.target.value.replace(/,/g, "").replace(/\D/g, "");
                        var formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        ev.target.value = formattedValue;
                    }}
                    tooltip={
                        <Typography fontSize={12}>The purchase price of the property.</Typography>
                    }
                />
            </InputContainer>
            <InputContainer>
                <TextField
                    name="loanAmount"
                    type="text"
                    label="Loan Required"
                    defaultValue="300,000"
                    max={propertyValue || 0}
                    min={0}
                    required
                    onBlur={() => checkValidValues()}
                    position="vertical"
                    register={register}
                    errors={errors}
                    onInput={(ev: any) => {
                        var value = ev.target.value.replace(/,/g, "").replace(/\D/g, "");
                        var formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        ev.target.value = formattedValue;
                    }}
                    tooltip={
                        <Typography fontSize={12}>
                            The amount you wish to borrow, excluding the deposit amount.
                        </Typography>
                    }
                />
            </InputContainer>
            {mortgageType &&
            (JSON.parse(mortgageType)?.MortgageType === "Buy_To_Let" ||
                JSON.parse(mortgageType)?.MortgageType === "Let_To_Buy") ? (
                <InputContainer>
                    <TextField
                        name="rentalIncome"
                        type="number"
                        label="Rental Income per Month"
                        required={
                            mortgageType &&
                            (JSON.parse(mortgageType)?.MortgageType === "Buy_To_Let" ||
                                JSON.parse(mortgageType)?.MortgageType === "Let_To_Buy")
                        }
                        position="vertical"
                        register={register}
                        errors={errors}
                        tooltip={
                            <Typography fontSize={12}>The rental income per month.</Typography>
                        }
                        min={0}
                    />
                </InputContainer>
            ) : null}
            {/* <InputContainer>
                <Dropdown
                    name="applicantType"
                    label="Applicant Type"
                    options={applicantType}
                    position="vertical"
                    register={register}
                />
            </InputContainer> */}
            {theme?.config?.hideOwnership ? null : (
                <>
                    <InputContainer>
                        <Dropdown
                            name="ownership"
                            label="Ownership"
                            options={[
                                {
                                    label: "Personal Name",
                                    value: "Personal Name",
                                },
                                {
                                    label: "Ltd Company",
                                    value: "Ltd Company",
                                },
                            ]}
                            position="vertical"
                            register={register}
                            tooltip={<Typography fontSize={12}>Ownership</Typography>}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Dropdown
                            name="reasonformortgage"
                            label="Type"
                            options={[
                                {
                                    label: "Purchase",
                                    value: "Purchase",
                                },
                                {
                                    label: "Remortgage",
                                    value: "Remortgage",
                                },
                            ]}
                            position="vertical"
                            register={register}
                            tooltip={<Typography fontSize={12}>Reason for Mortgage</Typography>}
                        />
                    </InputContainer>
                </>
            )}
        </div>
    );
};

const StyledRequirements = styled(Requirements)`
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
    margin: 0 12px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: ${config.device.upperMobileL}) and (max-width: ${config.device.tablet}) {
        width: auto;
        padding: 0 4px;
        margin: 0 4%;
        justify-content: space-between;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${config.device.mobileL}) {
        margin: auto;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(1, 1fr);
    }
`;

export default StyledRequirements;
