export const convertToInt = (num: string) => {
  return parseInt(num.replace(/,/g, "").replace(/\D/g, ""));
};

export const isEmail = (text: string) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return text.match(validRegex)?.length ? true : false;
};
