import Button, { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";

type CustomButtonProps = {
  className?: string;
  bg?: string;
  id?: string;
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: string;
};

const Btn = styled(Button)<CustomButtonProps & ButtonProps>`
  // display: block;
  // background: ${({ theme }) => theme.customization.primaryColor || "#FFF"};
  // color: ${({ theme }) => theme.customization.secondaryColor || "#000"};
  // font-size: ${({ fontSize }) =>
    Number(fontSize) ? `${fontSize}px` : fontSize || "1em"};
  // font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  // padding: 10px 20px;
  // text-align: center;
  // border: none;
  // border-radius: 5px;

  // &:focus {
  //   outline: 0;
  // }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.customization.primaryDark || "#FFF"};
  }
`;

const StyledButton: React.FC<CustomButtonProps & ButtonProps> = ({
  className,
  id,
  children,
  ...btnProps
}) => {
  return (
    <Btn className={className} id={id} {...btnProps}>
      {children}
    </Btn>
  );
};

export default StyledButton;
