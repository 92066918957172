import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";
import styled from "styled-components";

type LabelProps = {
  disabled?: boolean;
};

type DropdownProps = {
  className?: string;
  id?: string;
  name: string;
  disabled?: boolean;
  label?: string;
  value?: string;
  options: any[];
  position?: "horizontal" | "vertical";
  register: UseFormRegister<FieldValues>;
  defaultValue?: string;
  tooltip?: React.ReactNode;
};

const Input = styled.select<DropdownProps | any>`
  font-size: 16px;
  padding: 8px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 400px;
  background: linear-gradient(180deg, #efefef 0%, #d9d9d9 100%);
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  width: ${({ position }) => (position === "vertical" ? "100%" : "auto")};
  text-overflow: ellipsis;
  white-space: nowrap;
  &:focus {
    background: linear-gradient(180deg, #dfdfdf 0%, #c9c9c9 100%);
  }

  @media (max-width: 374px) {
    width: 150px;
  }
`;

const Label = styled.label<LabelProps>`
  position: relative;
  width: 100%;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282626;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const Dropdown: React.FC<DropdownProps> = ({
  className,
  id,
  name,
  label,
  options,
  register,
  defaultValue = "",
  tooltip = "",
  ...dropDownProps
}) => {
  if (!options) return null;

  return (
    <Label htmlFor={id} className={className}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" marginRight="5px">
          {label}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="right-start">
            <Box
              width={16}
              height={16}
              borderRadius={8}
              sx={{ backgroundColor: "primary.main" }}
            >
              <Typography
                lineHeight="1"
                variant="body2"
                color="white"
                textAlign={`center`}
              >
                ?
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Input id={id} {...dropDownProps} {...register(name)}>
        {/* <option hidden disabled selected></option> */}
        {React.Children.toArray(
          options.map(({ label, value }) => (
            <option value={value}>{label}</option>
          )),
        )}
      </Input>
    </Label>
  );
};

const StyledDropdown = styled(Dropdown)<DropdownProps>`
  flex-direction: ${({ position }) =>
    position === "vertical" ? "column" : "row"};
  align-items: ${({ position }) => position === "vertical" && "start"};
`;

export default StyledDropdown;
