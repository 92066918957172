import styled, { useTheme } from "styled-components";
import MpLogo from "../../assets/images/mp-logo.png";
import Separator from "../../components/shared/Separator/Separator";
import { useContext } from "react";
import ClientContext from "../../context/ClientContext";
import { Typography } from "@mui/material";
import config from "../../assets/data/config";
import { Theme } from "../../interfaces";

type StyledProps = {
  className?: string;
};

const LogoImage = styled.img`
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  max-height: 80px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.customization.primary.main || "#ff385c"};
  display: inline-block;
  vertical-align: middle;
`;

const MortgageSearchHeader: React.FC<StyledProps> = ({ className }) => {
  const { customization } = useContext(ClientContext);

  const theme = useTheme() as Theme;

  if (!customization.logo || theme?.config?.hideLogo) {
    return null;
  }

  return (
    <>
      <div className={`mortgage-search-header ${className}`}>
        <LogoImage src={customization.logo || MpLogo}></LogoImage>
        {/* <Typography variant="h3">
          <Title>Mortgage Calculator</Title>
        </Typography> */}
      </div>
      {/* <Separator width="98%" margin="0 12px" /> */}
    </>
  );
};

const StyledMortgageSearchHeader = styled(MortgageSearchHeader)`
  padding: 0 12px;
  display: inline-block;
  h3 {
    display: inline-block;
    vertical-align: middle;
  }

  @media (max-width: ${config.device.tablet}) {
    padding: 12px 0;
    img {
      display: block;
      margin: auto;
    }

    h3 {
      text-align: center;
      display: block;
    }
  }
`;

export default StyledMortgageSearchHeader;
