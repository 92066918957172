import styled from "styled-components";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { useContext, useState } from "react";
import FormContext from "../../context/FormContext";
import { Button, Tooltip, Typography } from "@mui/material";
import StyledText from "../shared/Text/Text";
import Modal from "../shared/Modal/Modal";
import MortgageFilters from "../MortgageFilters/MortgageFilters";
import config from "../../assets/data/config";

type SettingsProps = {
  className?: string;
  methods: any;
};

const MortgageLTVDisplay = styled.div`
  padding: 0 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  @media (min-width: ${config.device.mobileM}) and (max-width: ${config.device
      .tablet}) {
    width: 40%;
    height: auto;
    align-items: center;
  }

  @media (max-width: ${config.device.exMobileM}) {
    flex-direction: column;
    margin: auto;
    width: 80%;
    height: 100px;

    h3 {
      font-size: 24px;
    }
  }
`;

const LTV = styled.span`
  font-size: 40px;
  font-weight: 500;

  @media (min-width: ${config.device.mobileM}) and (max-width: ${config.device
      .tablet}) {
    font-size: 36px;
  }
`;

const LTVLabel = styled.span`
  font-size: 40px;

  @media (min-width: ${config.device.mobileM}) and (max-width: ${config.device
      .tablet}) {
    font-size: 26px;
    padding: 0;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 12px 0;

  button {
    margin: 0 12px;
    width: 100%;
  }

  @media (min-width: ${config.device.mobileM}) and (max-width: ${config.device
      .tablet}) {
    flex-direction: column;
    width: 30%;
  }
`;

const MortgageSettings: React.FC<SettingsProps> = ({ className, methods }) => {
  const { ltvRatio, isLoading } = useContext(FormContext);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={`mortgage-settings ${className}`}>
      {open ? (
        <Modal open={open} onClose={() => setOpen(false)} title="Filters">
          <MortgageFilters methods={methods} setOpen={setOpen} />
        </Modal>
      ) : null}
      <Tooltip
        title="Loan-to-Value refers to the percentage of loan compared to the value of the property."
        placement="top-start"
      >
        <MortgageLTVDisplay>
          <LTV>
            <Typography variant="h3">{`${ltvRatio}%`}</Typography>
          </LTV>
          <LTVLabel>
            <Typography variant="h3">LTV</Typography>
          </LTVLabel>
        </MortgageLTVDisplay>
      </Tooltip>
      <ButtonDiv>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: 2 / 5, marginY: "3px !important" }}
          onClick={() => setOpen(true)}
        >
          <StyledText fontSize="16" color="secondary" fontWeight="600">
            Filters
          </StyledText>
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="secondary"
          sx={{ width: 1 / 2, marginY: "3px !important" }}
          disabled={isLoading}
        >
          {/* cleanup sx */}
          <StyledText fontSize="16" color="secondary" fontWeight="600">
            Search
          </StyledText>
        </Button>
      </ButtonDiv>
    </div>
  );
};

const StyledMortgageSettings = styled(MortgageSettings)`
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
  width: 25%;
  flex-direction: column;
  margin: 0 20px;

  @media (min-width: ${config.device.mobileM}) and (max-width: ${config.device
      .tablet}) {
    flex-direction: row;
    width: 95%;
    margin: auto;

    h3 {
      font-size: 24px;
    }
  }

  @media (max-width: ${config.device.exMobileM}) {
    flex-direction: column;
    margin: auto;
    width: 90%;

    h3 {
      font-size: 24px;
    }
  }
`;

export default StyledMortgageSettings;
