import { Snackbar, SnackbarProps } from "@mui/material";

type CustomSnackbarProps = {
  className?: string;
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
};

const StyledSnackbar: React.FC<CustomSnackbarProps & SnackbarProps> = ({
  className,
  children,
  vertical,
  horizontal,
  ...snackbarProps
}) => {
  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} {...snackbarProps}>
      {children}
    </Snackbar>
  );
};

export default StyledSnackbar;
