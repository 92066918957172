import Slider, { sliderClasses, SliderProps } from "@mui/material/Slider";
import React from "react";
import { FieldValues, Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { Box, Tooltip, Typography } from "@mui/material";

type LabelProps = {
  disabled?: boolean;
};

type CustomSliderProps = {
  className?: string;
  id?: string;
  label?: string;
  name: string;
  position?: "horizontal" | "vertical";
  valueLabel?: string;
  methods?: UseFormReturn<FieldValues, any>;
  tooltip?: React.ReactNode;
};

// const Input = styled(Slider) <CustomSliderProps & SliderProps>`
//     // width: ${({ position }) => position === 'vertical' ? '100%' : '50%'};
//     width: 50%;
// `;

const SliderInput = muiStyled(Slider)<CustomSliderProps & SliderProps>(
  ({ theme, position }) => ({
    [`&.${sliderClasses.root}`]: {
      width: position === "vertical" ? "100%" : "50%",
      "margin-top": "12px",
      color: theme.palette.primary.main || "#ff385c",
    },
  }),
);

const Label = styled.label<LabelProps>`
  position: relative;
  width: 100%;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282626;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

const Span = styled.span`
  margin-right: 6px;
  white-space: nowrap;
  display: inline-block;
`;

const CustomSlider: React.FC<CustomSliderProps & SliderProps> = ({
  className,
  id,
  name,
  label,
  methods,
  value,
  valueLabel,
  tooltip = "",
  ...sliderProps
}) => {
  const { control } = methods!;
  return (
    <Label htmlFor={id} className={className}>
      <div>
        <Span>
          <Typography variant="h5">{label}</Typography>
        </Span>
        <Span>
          <Typography variant="h5">
            {value} {valueLabel}
          </Typography>
        </Span>
        {tooltip && (
          <Tooltip title={tooltip} placement="right-start">
            <Span>
              <Box
                width={16}
                height={16}
                borderRadius={8}
                sx={{ backgroundColor: "primary.main" }}
              >
                <Typography
                  lineHeight="1"
                  variant="body2"
                  color="white"
                  textAlign={`center`}
                >
                  ?
                </Typography>
              </Box>
            </Span>
          </Tooltip>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SliderInput
            id={id}
            name={name}
            onChange={(_, value) => field.onChange(value)}
            value={field.value}
            {...sliderProps}
          />
        )}
      />
    </Label>
  );
};

const StyledSlider = styled(CustomSlider)<CustomSliderProps & SliderProps>`
  flex-direction: ${({ position }) =>
    position === "vertical" ? "column" : "row"};
  align-items: ${({ position }) => position === "vertical" && "start"};
`;

export default StyledSlider;
