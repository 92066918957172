import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Lender } from "src/interfaces/lenders";
import styled, { useTheme } from "styled-components";
import * as yup from "yup";
import config from "../../assets/data/config";
import { commsApi, mortgageApi } from "../../axios";
import { Theme } from "../../interfaces";
import { convertToInt } from "../../utils/config";
import {
    getBTLBrokerMortgagePurpose,
    getDefaultMortgagePurpose,
} from "../MortgageRequirements/config";
import MortgageRequirements from "../MortgageRequirements/MortgageRequirements";
import MortgageSettings from "../MortgageSettings/MortgageSettings";
import FormContext from "./../../context/FormContext";
import {
    filterRunSourceData,
    formatRunSourceArguments,
} from "./../../utils/27tec";

type StyledProps = {
    className?: string;
    apiKey?: string;
};

const MortgageInputs = styled.div`
    display: flex;

    @media (max-width: ${config.device.tablet}) {
        display: block;
    }
`;

const schema = yup
    .object({
        paymentMethod: yup.string().required(),
        mortgagePurpose: yup.string().required(),
        propertyValue: yup.string().required(),
        loanAmount: yup.string().required(),
        mortgageTerm: yup.number().default(25).required(),
        rentalIncome: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .when("mortgagePurpose", ([mortgagePurpose], sch) => {
                const mortgageType = JSON.parse(mortgagePurpose)?.MortgageType;

                return mortgageType === "Buy_To_Let" ||
                    mortgageType === "Holiday_Let" ||
                    mortgageType === "Let_To_Buy"
                    ? sch.required()
                    : sch.notRequired();
            }),
        rateType: yup.array<Boolean>().default([true, true, true, true]),
        ratePeriod: yup.array<Boolean>().default([true, true, true]),
        lenders: yup.string().default("All"),
        ownership: yup.string().default("Personal Name"),
    })
    .required();

const Form: React.FC<StyledProps> = ({ className, apiKey }) => {
    const {
        setForm,
        lenders,
        setLenders,
        setIsLoading,
        setSearched,
        defaultmortgagetype,
        defaultpaymentmethod,
        defaultterm,
        defaultpropertyvalue,
        defaultloan,
        defaultrent,
        defaultownership,
    } = useContext(FormContext);

    const theme = useTheme() as Theme;

    const mortgageTypeObject = (
        theme?.apiKey === "bbef0922-77fa-44bd-8200-a8386e0d4223a" ||
        theme?.apiKey === "5f064283-7fc3-4a2d-96a3-69390702e986"
            ? getBTLBrokerMortgagePurpose(theme)
            : getDefaultMortgagePurpose(theme)
    )
        .filter((item) => !item.disabled)
        .find((item) => item.label === defaultmortgagetype);

    let mortgageType = mortgageTypeObject
        ? JSON.stringify({
              ...JSON.parse(mortgageTypeObject.value),
              TermUnit: "Years",
          })
        : JSON.stringify({
              ReasonForMortgage: "Purchase",
              MortgageType: "Standard",
              TermUnit: "Years",
          });

    if (apiKey === "cbdefc9b-1259-44c1-98cb-8abf018cdd3b") {
        mortgageType = mortgageTypeObject
        ? JSON.stringify({
              ...JSON.parse(mortgageTypeObject.value),
              TermUnit: "Years",
              ...({
                ReasonForMortgage: "Purchase",
              })
          })
        : JSON.stringify({
              ReasonForMortgage: "Purchase",
              MortgageType: "Standard",
              TermUnit: "Years",
          });
    }

    const methods = useForm({
        mode: "onTouched",
        defaultValues: {
            lenders: "All",
            ratePeriod: [true, true, true],
            rateType: [true, true, true, true],
            paymentMethod: defaultpaymentmethod ?? "Repayment",
            mortgagePurpose: mortgageType,
            rentalIncome:
                JSON.parse(mortgageType)?.MortgageType === "Buy_To_Let" ||
                JSON.parse(mortgageType)?.MortgageType === "Let_To_Buy"
                    ? defaultrent ?? 2250
                    : defaultrent ?? 0,
            mortgageTerm:
                JSON.parse(mortgageType)?.MortgageType === "Bridging_Loan"
                    ? defaultterm ?? 12
                    : defaultterm ?? 25,
            loanAmount: defaultloan ?? "300000",
            propertyValue: defaultpropertyvalue ?? "500000",
            ownership: defaultownership ?? "Personal Name",
        },
        resolver: yupResolver(schema),
    });
    const callApi = useCallback(
        async (f: any) => {
            f.mortgagePurpose = JSON.stringify({
                ...JSON.parse(f.mortgagePurpose),
                ...!theme?.config?.hideOwnership ? {
                    ReasonForMortgage: f.reasonformortgage === "Remortgage" ? "Remortgage" : "Purchase",
                } : {},
                ...!theme?.config?.hideOwnership 
                    ? (f.ownership === "Ltd Company" 
                        ? {
                            MortgageType: "Buy_To_Let",
                            BuyToLetDetails: { LimitedCompanySPV: "Yes" },
                            Filters: { LimitedCompanySPV: "Yes" },
                        } 
                        : {}) 
                    : {}
            });
            setIsLoading(true);
            try {
                const token = sessionStorage.getItem("token");

                if (lenders && lenders.length === 0) {
                    const { data: lenderData } = await commsApi.post(
                        "/api/27tec/lender",
                        { searchText: "" },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    if (lenderData.success && lenderData.items.length > 0) {
                        setLenders(lenderData.items);
                    }
                }
                const IncludedLenders = lenders
                    .map((lender: Lender) => {
                        if (f.lenders === "All") {
                            return {
                                string: lender.Code,
                            };
                        } else {
                            return f.lenders === lender.Name
                                ? {
                                      string: lender.Code,
                                  }
                                : null;
                        }
                    })
                    .filter(Boolean);
                f.IncludedLenders = IncludedLenders;
                // f.NearMissesDetails = {
                //   MaximumLTVBuffer: ltvRatio,
                // };\
                // console.log({
                //     f,
                // });
                const formData = formatRunSourceArguments(f);
                const { data } = await mortgageApi.post(
                    "/api/product/all",
                    formData
                );
                if (data?.results.length > 0)
                    setForm(
                        filterRunSourceData(
                            formData[0].Term, 
                            data.results, 
                            f,
                            theme?.config?.resultCount || 10
                        )
                    );
                else setForm([]);

                setIsLoading(false);
            } catch (err) {
                setForm([]);
                setIsLoading(false);
                throw new Error("API Error");
            }
        },
        [lenders, setForm, setIsLoading, setLenders]
    );

    const onSubmit = async (f: any) => {
        try {
            await callApi({
                ...f,
                loanAmount: convertToInt(f.loanAmount),
                propertyValue: convertToInt(f.propertyValue),
            });
            setSearched(true);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        callApi(methods.getValues());
    }, [callApi]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className={`form ${className}`}
            >
                <MortgageInputs>
                    <MortgageRequirements methods={methods} />
                    <MortgageSettings methods={methods} />
                    {/* <MortgageFilters methods={methods} /> */}
                </MortgageInputs>
            </form>
        </FormProvider>
    );
};

const StyledForm = styled(Form)`
    width: 100%;
`;

export default StyledForm;
