import { Alert, AlertProps } from "@mui/material";

type CustomAlertProps = {
  className?: string;
};

const style = {
  position: "absolute",
  top: "18px",
  right: "18px",
  width: "250px",
};

const StyledAlert: React.FC<CustomAlertProps & AlertProps> = ({
  className,
  children,
  ...alertProps
}) => {
  return (
    <Alert className={className} sx={style} {...alertProps}>
      {children}
    </Alert>
  );
};

export default StyledAlert;
