import axios from "axios";
import config from "./assets/data/config";

const commsApi: any = axios.create({
  baseURL: config.commsUrl,
});

const mortgageApi: any = axios.create({
  baseURL: config.mortgageApi,
});

export { commsApi, mortgageApi };
