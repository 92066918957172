import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

if (document.getElementById("root")) {
    const urlParams = new URLSearchParams(document.location.search);

    const apiKey = urlParams?.get("apiKey");
    const config = {
        defaultmortgagetype: urlParams?.get("defaultmortgagetype")!,
        defaultpaymentmethod: urlParams?.get("defaultpaymentmethod"),
        defaultterm: parseInt(urlParams?.get("defaultterm") || "25"),
        defaultpropertyvalue: urlParams?.get("defaultpropertyvalue"),
        defaultloan: urlParams?.get("defaultloan"),
        defaultrent: parseFloat(urlParams?.get("defaultrent") || "2250"),
        defaultownership: urlParams?.get("defaultownership"),
    };

    const rootDiv = ReactDOM.createRoot(document.getElementById("root")!);

    rootDiv.render(
        <BrowserRouter>
            <App apiKey={apiKey!} appConfig={config! as any} />
        </BrowserRouter>
    );
}

const renderApp = (apiKey: string, appConfig: any, element: HTMLElement) => {
    const rootDiv = ReactDOM.createRoot(element);

    rootDiv.render(<App apiKey={apiKey!} appConfig={appConfig} />);
};

export { renderApp };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
