export const lenders = [
  "All",
  "Accord",
  "Aldermore Mortgages",
  "Bank Of Ireland",
  "Barclays Bank",
  "Bath BS",
  "Bespoke by BOI",
  "Beverley BS",
  "Bluestone Mortgages",
  "BM Solutions",
  "Buckinghamshire BS",
  "Cambridge BS",
  "Chelsea BS",
  "CHL Mortgages",
  "Chorley BS",
  "Clydesdale Bank",
  "Coventry BS",
  "Darlington BS",
  "Digital Mortgages",
  "Dudley BS",
  "Earl Shilton BS",
  "Family Building Society (NCBS)",
  "Fleet Mortgages",
  "Foundation Home Loans",
  "Furness BS",
  "Generation Home",
  "Godiva Mortgages",
  "Halifax",
  "Hampshire Trust Bank",
  "Harpenden BS",
  "Hinckley & Rugby BS",
  "Hodge",
  "HSBC",
  "Interbay",
  "Kensington Mortgages",
  "Kent Reliance",
  "Keystone",
  "Landbay",
  "Leeds BS",
  "Leek United BS",
  "LendInvest",
  "LiveMore",
  "Lloyds Bank",
  "Loughborough BS",
  "Mansfield BS",
  "Market Harborough BS",
  "Marsden BS",
  "Melton Building Society",
  "Metro Bank",
  "Monmouthshire BS",
  "MPowered Mortgages",
  "Nationwide BS",
  "NatWest",
  "Newbury BS",
  "Newcastle BS",
  "Nottingham BS",
  "Paragon Banking Group PLC",
  "Penrith BS",
  "Pepper Money",
  "Platform",
  "Post Office",
  "Precise Mortgages",
  "Principality BS",
  "Saffron BS",
  "Santander UK Plc",
  "Scottish BS",
  "Scottish Widows Bank",
  "Shawbrook Bank",
  "Skipton BS",
  "Skipton International",
  "Stafford Railway BS",
  "State Bank of India UK",
  "Suffolk Building Society",
  "Swansea BS",
  "Teachers BS",
  "The Mortgage Lender",
  "The Mortgage Works",
  "Tipton & Coseley BS",
  "Together",
  "TSB",
  "United Trust Bank",
  "Vernon BS",
  "Vida Homeloans",
  "Virgin Money",
  "West One Secured Loans Ltd",
  "Yorkshire Building Society",
  "Zephyr Homeloans",
];
